import React from "react";
import { TextCom } from "./text";
import { useHandleOther } from "hook";
import { StyledTitle } from "theme";

type TitleProps = {
  text: any;
  class?: string;
};


export const Title: React.FC<TitleProps> = (props) => {
  const { translate, langStore } = useHandleOther();
  let langCode = langStore?.code;
  return (
    <StyledTitle className={`title d-flex align-items-center ${props?.class}`}>
      <hr />
      <TextCom as="h1" size={langCode == 'en' ? "xxxl" : "xxl"} weight="lg">
        {props?.class !== "verticle"
          ? translate(props?.text, props?.text)
          : props?.text}
      </TextCom>
    </StyledTitle>
  );
};
