import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  useOther,
  useHandleOther,
  useCart,
  useHandleCart,
  useHook,
} from "hook";
import {
  TextCom,
  FormContainerCom,
  InputCom,
  ButtonCom,
  MyNewMapCom,
} from "components";

type ShippingFormProps = {
  btn_name?: any;
  route?: string;
  modal?: any;
  props?: any;
  from?: any;
  guestForm?: any;
  getGuestform?: any;
  handleNext?: any;
  delitype?: any;
};

export const ShippingForm: React.FC<ShippingFormProps> = ({
  btn_name,
  route,
  modal,
  from,
  guestForm,
  getGuestform,
  handleNext,
  delitype,
}) => {
  const navigate = useNavigate();
  const { getMetaData_data } = useOther();
  const { translate, langStore } = useHandleOther();
  const {
    selectShippingAddress_data,
    getShippingLocations_data,
    dispatch,
    CartAction,
  } = useCart();
  const {
    handleCreateShippingAddress,
    handleEditShippingAddress,
    handleSelectShipping,
  } = useHandleCart();
  const langCode = langStore?.code;
  const [loc, setLoc] = useState({
    lat: 0,
    lng: 0,
  });
  useEffect(() => {
    dispatch(CartAction.getShippingAddress());
    dispatch(CartAction.getAllLocation({ langCode }));
  }, []);
  let initialValues = {
    name:
      from === "edit"
        ? selectShippingAddress_data?.selected_item?.name
        : from === "guest_edit"
        ? getGuestform[0]?.name
        : "",

    phone:
      from === "edit"
        ? selectShippingAddress_data?.selected_item?.mobile
        : from === "guest_edit"
        ? getGuestform[0]?.mobile
        : "",

    state:
      from === "edit"
        ? selectShippingAddress_data?.selected_item?.state?.id
        : from === "guest_edit"
        ? getGuestform[0]?.state?.id
        : "",

    township:
      from === "edit"
        ? selectShippingAddress_data?.selected_item?.township?.id
        : from === "guest_edit"
        ? getGuestform[0]?.township?.id
        : "",

    address:
      from === "edit"
        ? selectShippingAddress_data?.selected_item?.address1
        : from === "guest_edit"
        ? getGuestform[0]?.address1
        : "",
  };

  const validationSchema = (yup: any) =>
    yup.object({
      name: yup
        .string()
        .required(`${translate("name_required", "Name is required")}`),
      phone: yup.number().required("Phone no is requried"),
      state: yup.string(),
      township: yup.string(),
      address: yup.string(),
    });

  const onHandleDataSubmit = async (values: any, actions: any) => {
    actions.setSubmitting(true);
    if (guestForm) {
      const State: any = getShippingLocations_data[0]?.state_ids?.filter(
        (state: any) => Number(state.id) === Number(values?.state)
      );

      const TownShip: any = State[0]?.township_ids?.filter(
        (township: any) => Number(township.id) === Number(values?.township)
      );

      guestForm([
        {
          name: values?.name,
          mobile: values?.phone,
          state: { id: State[0]?.id, name: State[0]?.name },
          township: { id: TownShip[0]?.id, name: TownShip[0]?.name },
          address1: values?.address,
          country: { name: getShippingLocations_data?.name },
          // lat: loc?.lat,
          // long: loc.lng,
        },
      ]);
      if (from === "guestadd") {
        navigate("/order/payment", {
          state: {
            address: {
              name: values?.name,
              mobile: values?.phone,
              state: { id: State[0]?.id, name: State[0]?.name },
              township: { id: TownShip[0]?.id, name: TownShip[0]?.name },
              address1: values?.address,
              country: { name: getShippingLocations_data?.name },
            },
            type: delitype === "pickup" ? "pickup" : "delivery",
          },
        });
      }
    }
    setTimeout(async () => {
      let postData = {
        name: values?.name,
        address: values?.address,
        state: values?.state,
        township: values?.township,
        user_phone: values?.phone,
        street: "",
        // lat: loc.lat,
        // long: loc.lng,
      };
      if (from === "edit") {
        await handleEditShippingAddress({
          edit_id: selectShippingAddress_data?.selected_item?.id,
          ...postData,
        });
      } else {
        let res = await handleCreateShippingAddress(postData);
        // if (guestForm) {
        //   await handleSelectShipping({ id: res?.payload?.ID });
        // }
      }
      actions.setSubmitting(false);
      actions.resetForm();
    }, 1000);
    if (route) navigate(route, { replace: false });
    if (modal) modal(false);
  };

  let deliinfo_text = "";
  if (getMetaData_data && getMetaData_data["checkout-deli-info"]?.text_one) {
    deliinfo_text = getMetaData_data["checkout-deli-info"]?.text_one;
  }

  return (
    <>
      {/* <TextCom size="xl" weight="xl" textAlign="left" className="mb-3">
        {from === "edit"
          ? translate("edit-shipping-address", "EDIT SHIPPING ADDRESS")
          : translate("add-shipping-form", "Add Shipping Form")}
      </TextCom> */}
      <FormContainerCom
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values: any, actions: any) =>
          onHandleDataSubmit(values, actions)
        }
      >
        {(formikProps: any) => {
          const { values } = formikProps;
          return (
            <div className="info">
              <InputCom
                placeholder={"User Name"}
                name="name"
                type="text"
                label={translate("name", "Name")}
                required
              />
              <InputCom
                placeholder={"Phone"}
                name="phone"
                type="tel"
                label={translate("phone", "Name")}
                required
              />
              <InputCom
                placeholder={"Choose State"}
                label={translate("states-divisions", "State")}
                name="state"
                required
                input_as="select"
                defaultValue=""
              >
                {<option value="">{translate("select", "Select")}</option>}
                {getShippingLocations_data &&
                  getShippingLocations_data[0]?.state_ids?.map((item: any) => {
                    return <option value={[item?.id]}>{item?.name}</option>;
                  })}
              </InputCom>
              <InputCom
                placeholder={"Choose Township"}
                label={translate("townships", "Townships")}
                name="township"
                input_as="select"
                defaultValue=""
                required
              >
                {
                  <option value="">
                    {translate(
                      "choose-after-state/divison",
                      "Choose After State/Divison"
                    )}
                  </option>
                }
                {values?.state &&
                  getShippingLocations_data &&
                  getShippingLocations_data[0]?.state_ids
                    ?.filter(
                      (state: any) => Number(state.id) === Number(values.state)
                    )[0]
                    ?.township_ids?.map((town: any) => {
                      return <option value={[town?.id]}>{town?.name}</option>;
                    })}
              </InputCom>
              <InputCom
                name="address"
                type="text"
                input_as="textarea"
                rows="4"
                label={translate("address", "Address")}
                required
                placeholder={deliinfo_text ? deliinfo_text : translate("address", "Address")}
                // placeholder={from === "edit" ? deliinfo_text : translate("address", "Address")}

              />
              {/* <div className="map-wrap mt-3 mb-5">
                <MyNewMapCom
                  lat={0}
                  lng={0}
                  handlePinMarker={(place: any) => {
                    setLoc({
                      ...loc,
                      lat: place.lat,
                      lng: place.lng,
                    });
                  }}
                />
              </div> */}
              <div
                className={`buttons d-flex ${
                  from === "edit" ? "justify-content-center" : "w-100"
                } mb-3 gap-2`}
              >
                {from === "edit" || from === "guest_edit" ? (
                  <ButtonCom
                    type="cancel"
                    className={`mt-3 ${from === "edit" ? "" : "w-100"}`}
                    btnBorderRadius="xxxs"
                    bgcolor="primary_light2"
                    color="light"
                    onClick={(e: any) => formikProps.resetForm()}
                  >
                    <TextCom color="light">
                      {translate("cancel", "Cancel")}
                    </TextCom>
                  </ButtonCom>
                ) : (
                  ""
                )}
                <ButtonCom
                  type="submit"
                  className={`mt-3 ${
                    from === "edit" ? "" : "w-100"
                  } submit-btn`}
                  btnBorderRadius="xxxs"
                  bgcolor="primary_light2"
                  color="light"
                  style={{ minWidth: from === "add" ? "100%" : "" }}
                  // disabled={from !== "edit" && delitype === "" ? true : false}
                >
                  <TextCom color="light">
                    {from === "edit" || from === "guest_edit"
                      ? translate("save", "Save")
                      : btn_name}
                  </TextCom>
                </ButtonCom>
              </div>
            </div>
          );
        }}
      </FormContainerCom>
    </>
  );
};
