import React from "react";

import { StyledFieldWrapper, StyledInputContainer, StyledLabel } from "theme";
import { TextCom } from "../typo";

type FieldWrapperComProps = {
  children?: React.ReactNode;
  meta?: any;
  required?: boolean;
  label?: string;
  fun?: any;
  parameter?: any;
};
export const FieldWrapperCom: React.FC<FieldWrapperComProps> = ({
  children,
  meta,
  label,
  required,
  fun,
  parameter,
  ...props
}) => {
  if (fun) {
    // console.log(fun, "fun");
  }
  return (
    <StyledFieldWrapper {...props} className="field-wrapper">
      {label && (
        <StyledLabel {...props}>
          <TextCom className="label_txt">{label}</TextCom>
          {required && <TextCom color="danger">*</TextCom>}
        </StyledLabel>
      )}
      {fun ? (
        <StyledInputContainer onClick={() => fun(parameter)} {...props}>
          {children}
        </StyledInputContainer>
      ) : (
        <StyledInputContainer {...props}>{children}</StyledInputContainer>
      )}
      {meta?.touched && meta?.error && (
        <TextCom className="err_msg" color="danger" size="xs">
          {meta?.error}
        </TextCom>
      )}
    </StyledFieldWrapper>
  );
};
