import { useCart, useProduct } from "../../common";

type PropsType = {
  type?: string;
  item?: any;
  w_index?: any;
  c_index?: any;
  uid?: any;
};

let tmp: any = null;
let timeout = 500;
export const useHandleQty = ({
  type = "productbyid",
  item,
  w_index = 0,
  c_index = 0,
  uid,
  ...props
}: PropsType) => {
  const {
    getProductById_data,
    getWishlist_data,
    checkStock_isLoading,
    ProductAction,
    dispatch,
  } = useProduct();
  const {
    createCart_data,
    getCart_data,
    updateQtyInCart_isLoading,
    CartAction,
  } = useCart();

  const handleQty = () => {
    if (type === "productbyid") {
      return getProductById_data?.quantity || "";
    } else if (type === "wishlist") {
      // console.log(getWishlist_data)
      // if (getWishlist_data?.[w_index]?.quantity != getWishlist_data?.[w_index]?.quantity_control_step
      //   && getWishlist_data?.[w_index]?.quantity < getWishlist_data?.[w_index]?.quantity_control_step
      //   && getWishlist_data?.[w_index]?.quantity_control_step > 1
      // ) {
      //   return getWishlist_data?.[w_index]?.quantity_control_step || "";
      // }
      // else{
        return getWishlist_data?.[w_index]?.quantity || "";
      // }
    } else if (type === "cartlist") {
      return getCart_data?.order_lines?.[c_index]?.quantity || "";
    } else {
      return;
    }
  };

  const handleQtyFun = async (quantity: any, uid: any, e: any) => {
    // console.log(e)
    // console.log(quantity)
    // if (e!= null) {
    //   const roundedUserValue = Math.max(Math.round(quantity / getProductById_data.quantity_control_step) * getProductById_data.quantity_control_step, getProductById_data.quantity_control_step);
    //   const halfwayPoint = getProductById_data.quantity_control_step + (getProductById_data.quantity_control_step / 2);
    
    //   if (roundedUserValue < halfwayPoint) {
    //     quantity = getProductById_data.quantity_control_step;
    //   } else {
    //     const nextMultiple = Math.ceil(roundedUserValue / getProductById_data.quantity_control_step) * getProductById_data.quantity_control_step;
    //     quantity = nextMultiple;
    //   }
    // }

  
      if (e!= null && (quantity >= 0)){
        const roundedUserValue = Math.max(Math.round(quantity / getProductById_data.quantity_control_step) * getProductById_data.quantity_control_step, getProductById_data.quantity_control_step);
        const halfwayPoint = getProductById_data.quantity_control_step + (getProductById_data.quantity_control_step / 2);
      
        if (roundedUserValue < halfwayPoint) {
          quantity = getProductById_data.quantity_control_step;
        } else {
          const nextMultiple = Math.ceil(roundedUserValue / getProductById_data.quantity_control_step) * getProductById_data.quantity_control_step;
          quantity = nextMultiple;
        }
      }

      if (type === "productbyid") {
        let pos_data: any = {
          type: "productbyid",
          item: getProductById_data,
          product_id: getProductById_data?.product_id,
          quantity,
          // quantity: getProductById_data?.buy_limit === 0 ? quantity : getProductById_data?.buy_limit >= quantity ? quantity : getProductById_data?.buy_limit,
        };
        if (uid) {
          pos_data.uom_id = uid;
        }

        if (getProductById_data?.attributes?.data?.length > 0) {
          pos_data.variant_id =
            getProductById_data?.attributes?.selected_variant_ids?.length > 0
              ? getProductById_data?.attributes?.selected_variant_ids.toString()
              : null;
        }
        // console.log(getProductById_data?.cap_data?.length > 0, "checkStock muda")
        // console.log(getProductById_data?.cap_data[0]?.length > 0, "checkStock muda")

        if (getProductById_data?.cap_data[0]?.length > 0) {
          pos_data.cap_id =
            getProductById_data?.cap_data?.selected_variant_ids?.length > 0
              ? getProductById_data?.cap_data?.selected_variant_ids.toString()
              : null;
        }

        if (createCart_data?.order_id) {
          pos_data.order_id = createCart_data?.order_id;
        }

        if (getProductById_data?.package_product_type === true) {
          let package_item: any = [];
          getProductById_data?.package_products?.data?.map((item: any) => {
            package_item.push({
              product_id: item?.product_template_id,
              variant_id: item?.attributes?.selected_variant_ids?.toString(),
            });
          });
          pos_data.package_item = package_item;
        }
        if (!checkStock_isLoading) {
          if (quantity == 0) {
            // console.log(quantity)
            await dispatch(
              ProductAction.setProductDetail({ key: "quantity", data: "" })
            );
          }
          else if (quantity > 0) {
            await dispatch(
              ProductAction.setProductDetail({ key: "quantity", data: quantity })
            );
          }
          // else if(quantity == 0){
          //   quantity = 100;
          //   await dispatch(
          //     ProductAction.setProductDetail({ key: "quantity", data: quantity })
          //   );
          // }
          // if (quantity > 0 && getProductById_data?.buy_limit === 0) {
          //   await dispatch(ProductAction.setProductDetail({ key: 'quantity', data: quantity }))
          // } else if (quantity <= getProductById_data?.buy_limit) {
          //   await dispatch(ProductAction.setProductDetail({ key: 'quantity', data: quantity }))
          // }
          // console.log(pos_data);
          clearTimeout(tmp);
          tmp = setTimeout(async () => {
            // console.log(uid, "is this uom change?")
            // if (getProductById_data?.buy_limit === 0) {
            // console.log(pos_data, "is this uom change?")
            getProductById_data?.product_id &&
              (await dispatch(ProductAction.checkStock(pos_data)));

            // } else if (pos_data?.quantity <= getProductById_data?.buy_limit) {
            //   getProductById_data?.product_id && (await dispatch(ProductAction.checkStock(pos_data)))
            //   if (pos_data?.quantity === getProductById_data?.buy_limit) {
            //     dispatch(ProductAction.setProductDetail({ key: 'check_stock', data: { in_stock: false, stock_balance: getProductById_data?.buy_limit, desc: `Maximum Buy limit is ${pos_data?.quantity}` } }))
            //   }
            // }
          }, timeout);
        }
      } else if (type === "wishlist") {
        let pos_data = {
          type: "wishlist",
          product_id: item?.product_template_id,
          w_index,
          item,
          quantity,
        };
        // if (!checkStock_isLoading) {
        if (quantity > 0) {
          await dispatch(
            ProductAction.setWishlistList({
              key: "quantity",
              w_index,
              data: quantity,
            })
          );
        }
        clearTimeout(tmp);
        tmp = setTimeout(async () => {
          await dispatch(ProductAction.checkStock(pos_data));
        }, timeout);
        // }
      } else if (type === "cartlist") {
        let pos_data: any = {
          order_id: createCart_data?.order_id,
          product_id: item?.product_template_id,
          variant_id: item?.variant_id,
          c_index,
          item,
          quantity,
        };
        if (!item?.variant_id) {
          delete pos_data.variant_id;
        }

        if (item?.package_data_line?.length > 0) {
          pos_data.package_item = item?.package_data_line;
        }
        // if (!updateQtyInCart_isLoading) {
        if (quantity > 0) {
          let obj = {
            c_index,
            quantity,
          };
          await dispatch(CartAction.setQtyInCartList(obj));
        }
        clearTimeout(tmp);
        tmp = setTimeout(async () => {
          createCart_data?.order_id &&
            (await dispatch(CartAction.updateQtyInCart(pos_data)));
        }, timeout);
        // }
      }
    // }, timeout);
  };

  return [handleQty(), handleQtyFun];
  
};
