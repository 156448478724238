import React, { useState, useEffect } from "react";

import { TextCom } from "../../common";
import { useOther, useProduct, useHandleOther } from "hook";
import { moneyFormat } from "utils";

type StockVariantMsgProps = {
  from?: string;
  item?: any;
  HandleImage?: any;
  style?: any;
};

export const StockVariantMsgMod: React.FC<StockVariantMsgProps> = (props) => {
  const { from, item, HandleImage, style } = props;
  const { getProductById_data } = useProduct();
  const { translate } = useHandleOther();
  const { langStore } = useOther();
  let langCode = langStore.code;
  let from_data = from === "cartlist" ? item : getProductById_data;

  let buy_limit_msg =
    getProductById_data?.check_stock?.qty_cart +
      getProductById_data?.check_stock?.available_req_qty ===
    getProductById_data?.buy_limit
      ? true
      : false;

  // ---------- finding deselected attributes -----------
  let atts: any = [];
  getProductById_data?.attributes?.data?.map((att: any) => atts.push(att));
  let selected_att_ids: any = [];

  let deselected: any = null;
  if (getProductById_data?.attributes?.selected_attribute) {
    getProductById_data?.attributes?.selected_attribute?.map((s: any) =>
      selected_att_ids?.push(s?.id)
    );

    deselected = atts.filter((x: any) => !selected_att_ids.includes(x?.id));
  }

  let captype_selected: any =
    getProductById_data?.cap_data?.obj_selected_variant?.length > 0
      ? getProductById_data?.cap_data?.obj_selected_variant[0]?.id
      : null;
  let capcolor_selected: any =
    getProductById_data?.cap_data?.selected_attribute?.length > 0
      ? getProductById_data?.cap_data?.selected_attribute[0]?.id
      : null;

  // console.log("from", from);
  // console.log("HandleImage", HandleImage);
  // console.log("getProductById_data", getProductById_data);
  return HandleImage == "Out of Stock" ? (
    <>
      {getProductById_data?.check_stock?.desc ? (
        <TextCom color="danger" size="xs">
          {translate("out_of_stock", "Out of Stock")}
        </TextCom>
      ) : getProductById_data?.check_stock?.available_req_qty > 0 ? (
        getProductById_data?.check_stock?.available_req_qty < 10 ? (
          <TextCom color="danger" size="xs">
            {langCode === "en"
              ? `Only ${getProductById_data?.check_stock?.available_req_qty} left in stock `
              : `${moneyFormat(
                  getProductById_data?.check_stock?.available_req_qty,
                  langCode
                )} ခုသာ ကျန်ပါတော့သည်`}
          </TextCom>
        ) : (
          getProductById_data?.check_stock?.status === false && (
            <TextCom color="danger" size="xs">
              {langCode === "en"
                ? `Only ${getProductById_data?.check_stock?.available_req_qty} left in stock`
                : `${moneyFormat(
                    getProductById_data?.check_stock?.available_req_qty,
                    langCode
                  )} ခုသာ ကျန်ပါတော့သည်`}
            </TextCom>
          )
        )
      ) : getProductById_data?.check_stock?.instock === false ||
        getProductById_data?.check_stock?.in_stock === false ? (
        <TextCom color="danger" size="xs">
          {translate("out_of_stock", "Out of Stock")}
        </TextCom>
      ) : (
        getProductById_data?.check_stock?.status === false && (
          <TextCom color="danger" size="xs">
            {getProductById_data?.check_stock?.error}
          </TextCom>
        )
      )}
    </>
  ) : from === "other" ? (
    <>
      {item?.check_stock?.desc ? (
        <TextCom color="danger" size="xs">
          {translate(item?.check_stock?.desc, item?.check_stock?.desc)}
        </TextCom>
      ) : item?.check_stock?.available_req_qty > 0 ? (
        item?.check_stock?.available_req_qty < 10 ? (
          <TextCom color="danger" size="xs">
            {langCode === "en"
              ? `Only ${item?.check_stock?.available_req_qty} left in stock`
              : `${moneyFormat(
                  item?.check_stock?.available_req_qty,
                  langCode
                )} ခုသာ ကျန်ပါတော့သည်`}
          </TextCom>
        ) : (
          item?.check_stock?.status === false && (
            <TextCom style={style} color="danger" size="xs">
              {langCode === "en"
                ? `Only ${item?.check_stock?.available_req_qty} left in stock`
                : `${moneyFormat(
                    item?.check_stock?.available_req_qty,
                    langCode
                  )} ခုသာ ကျန်ပါတော့သည်`}
            </TextCom>
          )
        )
      ) : item?.check_stock?.instock === false ||
        item?.check_stock?.in_stock === false ? (
        <TextCom color="danger" size="xs">
          {translate("out_of_stock", "Out of Stock")}
        </TextCom>
      ) : (
        item?.check_stock?.status === false && (
          <TextCom color="danger" size="xs">
            {item?.check_stock?.error}
          </TextCom>
        )
      )}
    </>
  ) : (
    <>
      {/* {console.log(getProductById_data)} */}
      {/* {getProductById_data?.check_stock?.available_req_qty > 0 && (
        <TextCom color="danger" size="xs">
          {langCode === "en"
            ? `Only ${getProductById_data?.check_stock?.available_req_qty} left in stock`
            : `${moneyFormat(
                getProductById_data?.check_stock?.available_req_qty,
                langCode
              )} ခုသာ ကျန်ပါတော့သည်`}
          aye yo
        </TextCom>
      )} */}
      {getProductById_data?.package_product_type === false &&
      getProductById_data?.check_stock?.desc ? (
        // deselected?.length > 0 ? (
        deselected !== null ? (
          deselected?.length !== 0 ? (
            <TextCom color="danger" size="sm" className="v_message">
              {langCode === "en" ? (
                <>
                  Please Choose
                  {deselected?.map((item: any) => (
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate(item?.name, item?.name)}
                    </TextCom>
                  ))}
                  .
                </>
              ) : (
                <>
                  ကျေးဇူးပြု၍{" "}
                  {deselected?.map((item: any) => (
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate(item?.name, item?.name)}
                    </TextCom>
                  ))}{" "}
                  ကို ရွေးချယ်ပါ
                </>
              )}
            </TextCom>
          ) : deselected?.length == 0 && captype_selected == null ? (
            <TextCom color="danger" size="sm" className="v_message">
              {langCode === "en" ? (
                <>
                  Please Choose
                  <TextCom as="span" color="danger" size="sm">
                    {" "}
                    {translate("Cap Type", "Cap Type")}
                  </TextCom>
                  .
                </>
              ) : (
                <>
                  ကျေးဇူးပြု၍{" "}
                  <TextCom as="span" color="danger" size="sm">
                    {" "}
                    {translate("Cap Type", "Cap Type")}
                  </TextCom>
                  ကို ရွေးချယ်ပါ
                </>
              )}
            </TextCom>
          ) : (
            deselected?.length == 0 &&
            captype_selected !== null &&
            capcolor_selected == null && (
              <TextCom color="danger" size="sm" className="v_message">
                {langCode === "en" ? (
                  <>
                    Please Choose
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate("Cap Color", "Cap Color")}
                    </TextCom>
                    .
                  </>
                ) : (
                  <>
                    ကျေးဇူးပြု၍{" "}
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate("Cap Color", "Cap Color")}
                    </TextCom>
                    ကို ရွေးချယ်ပါ
                  </>
                )}
              </TextCom>
            )
          )
        ) : getProductById_data?.attributes?.data?.length == 0 &&
          getProductById_data?.cap_data &&
          getProductById_data?.cap_data[0].length > 0 ? (
          captype_selected == null ? (
            <TextCom color="danger" size="sm" className="v_message">
              {langCode === "en" ? (
                <>
                  Please Choose
                  <TextCom as="span" color="danger" size="sm">
                    {" "}
                    {translate("Cap Type", "Cap Type")}
                  </TextCom>
                  .
                </>
              ) : (
                <>
                  ကျေးဇူးပြု၍{" "}
                  <TextCom as="span" color="danger" size="sm">
                    {" "}
                    {translate("Cap Type", "Cap Type")}
                  </TextCom>
                  ကို ရွေးချယ်ပါ
                </>
              )}
            </TextCom>
          ) : (
            captype_selected !== null &&
            capcolor_selected == null && (
              <TextCom color="danger" size="sm" className="v_message">
                {langCode === "en" ? (
                  <>
                    Please Choose
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate("Cap Color", "Cap Color")}
                    </TextCom>
                    .
                  </>
                ) : (
                  <>
                    ကျေးဇူးပြု၍{" "}
                    <TextCom as="span" color="danger" size="sm">
                      {" "}
                      {translate("Cap Color", "Cap Color")}
                    </TextCom>
                    ကို ရွေးချယ်ပါ
                  </>
                )}
              </TextCom>
            )
          )
        ) : (
          <TextCom color="danger" size="sm" className="hello">
            {langCode === "en" ? (
              <>
                Please Choose {getProductById_data?.attributes?.data[0]?.name} .
              </>
            ) : (
              <>
                ကျေးဇူးပြု၍ {getProductById_data?.attributes?.data[0]?.name} ကို
                ရွေးချယ်ပါ
              </>
            )}

            {/* {translate(
                  getProductById_data?.check_stock?.desc,
                  getProductById_data?.check_stock?.desc
                )} */}
          </TextCom>
        )
      ) : // ) : (
      //   <></>
      // )
      // getProductById_data?.check_stock?.stock_balance < 10 ? (
      //   <TextCom color="danger" size="xs" className="">
      //     {langCode === 'en' ? `Only ${getProductById_data?.check_stock?.stock_balance} left in stock` : `${getProductById_data?.check_stock?.stock_balance} ခုသာ ကျန်ပါတော့သည်`}
      //   </TextCom>
      // ) : (
      //   getProductById_data?.check_stock?.in_stock === false && (
      //     <TextCom color="danger" size="xs" className="">
      //       {langCode === 'en' ? `Only ${getProductById_data?.check_stock?.stock_balance} left in stock` : `${getProductById_data?.check_stock?.stock_balance} ခုသာ ကျန်ပါတော့သည်`}
      //     </TextCom>
      //   )
      // )

      getProductById_data?.check_stock?.available_req_qty !== 0 ? (
        getProductById_data?.check_stock?.available_req_qty > 0 ? (
          <TextCom color="danger" size="sm" className="stock-msg">
            {buy_limit_msg === false && langCode === "en"
              ? `Only ${getProductById_data?.check_stock?.available_req_qty} left in stock`
              : buy_limit_msg === false &&
                `${moneyFormat(
                  getProductById_data?.check_stock?.available_req_qty,
                  langCode
                )} ခုသာ ကျန်ပါတော့သည်`}
            {buy_limit_msg === true && langCode === "en"
              ? `Your Buy Limit is ${getProductById_data?.check_stock?.available_req_qty} item.`
              : buy_limit_msg === true &&
                `အများဆုံး ${moneyFormat(
                  getProductById_data?.check_stock?.available_req_qty,
                  langCode
                )} ခုသာ ၀ယ်ယူခွင့်ရှိပါသည်။`}
            {/* aye yo yeet */}
          </TextCom>
        ) : (
          <TextCom color="danger" size="sm" className="stock-msg">
            {getProductById_data?.check_stock?.error}
          </TextCom>
        )
      ) : getProductById_data?.check_stock?.error ===
        "buy limit is reached!" ? (
        <TextCom color="danger" size="sm" className="stock-msg">
          {translate(
            getProductById_data?.check_stock?.error,
            getProductById_data?.check_stock?.error
          )}
        </TextCom>
      ) : (
        getProductById_data?.check_stock?.status === false && (
          <TextCom color="danger" size="sm" className="stock-msg">
            {translate("out_of_stock", "out_of_stock")}
          </TextCom>
        )
      )}
    </>
  );
};
