import React, { useEffect, useRef, useState } from "react";
import Media from "react-media";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";
import { TbTruckDelivery } from "react-icons/tb";
import { MdPlayCircle } from "react-icons/md";

import {
  DetailPreview,
  NewArrivalSlider,
  Container,
  TextCom,
  NewTabCom,
  CollapseCom,
  CollapsePanel,
  BreadcrumbCom,
  ProductCard,
  CardCom,
  SkeletonWrap,
  ProductCardMap,
} from "components";
import { useOther, useHandleOther, useProduct, useHandleQty } from "hook";
import { StyledDetail } from "theme";
import Image from "../../asset/icon/pladco";
import { en2mm_num, joinSpace, moneyFormat } from "utils";
import { productStore } from "service";

export type IProductDetailProps = {
  ref?: any;
};

export const ProductDetial: React.FC<IProductDetailProps> = (props) => {
  const params = useParams();
  const { langStore, OtherAction, dispatch, getContactUsPage_data } =
    useOther();
  const { getProductById_data, ProductAction, getRecentViewProduct_data } =
    useProduct();
  const { translate } = useHandleOther();
  const langCode = langStore?.code;
  const navigate = useNavigate();

  let GetDetailItem = Number(params?.id);

  useEffect(() => {
    dispatch(ProductAction.getProductById({ id: GetDetailItem, langCode }));
    let pos_data: any = {
      type: "productbyid",
      item: getProductById_data,
      product_id: getProductById_data?.product_id,
      quantity: 1,
      // quantity: getProductById_data?.buy_limit === 0 ? quantity : getProductById_data?.buy_limit >= quantity ? quantity : getProductById_data?.buy_limit,
    };

    if (getProductById_data?.attributes?.data?.length > 0) {
      pos_data.variant_id =
        getProductById_data?.attributes?.selected_variant_ids?.length > 0
          ? getProductById_data?.attributes?.selected_variant_ids.toString()
          : null;
    }
    dispatch(ProductAction.checkStock(pos_data));
  }, [GetDetailItem, langCode]);

  //wrapper elements to set loading images
  const alternativeRef = useRef<HTMLDivElement>(null);
  const galleryRef = useRef<HTMLDivElement>(null);

  const dataSource = [
    {
      title: translate("detail", "Detail"),
      desc: `${getProductById_data?.description?.detail}`,
      key: "detail",
      header_render: (x: any, y: any, i: any, active: any) => {
        return (
          getProductById_data?.description?.detail.length > 0 && (
            <div className={`title_wrap ${active ? "active" : undefined} mb-3`}>
              <TextCom
                textAlign="center"
                color={active ? "primary" : "text"}
                weight="xl"
              >
                DETAIL
              </TextCom>
            </div>
          )
        );
      },
    },
    {
      title: "HOW TO USE",
      desc: `${getProductById_data?.description?.howtouse}`,
      key: "howtouse",
      header_render: (x: any, y: any, i: any, active: any) => {
        return (
          getProductById_data?.description?.howtouse.length > 0 && (
            <div className={`title_wrap ${active ? "active" : undefined} mb-3`}>
              <TextCom
                textAlign="center"
                color={active ? "primary" : "text"}
                weight="xl"
              >
                HOW TO USE
              </TextCom>
            </div>
          )
        );
      },
    },
    {
      title: "BENEFITS",
      desc: `${getProductById_data?.description?.benefits}`,
      key: "BENEFITS",
      header_render: (x: any, y: any, i: any, active: any) => {
        return (
          getProductById_data?.description?.benefits.length > 0 && (
            <div className={`title_wrap ${active ? "active" : undefined} mb-3`}>
              <TextCom
                textAlign="center"
                color={active ? "primary" : "text"}
                weight="xl"
              >
                BENEFITS
              </TextCom>
            </div>
          )
        );
      },
    },
    {
      title: "RESULT",
      desc: `${getProductById_data?.description?.result}`,
      key: "RESULT",
      header_render: (x: any, y: any, i: any, active: any) => {
        return (
          getProductById_data?.description?.result.length > 0 && (
            <div className={`title_wrap ${active ? "active" : undefined} mb-3`}>
              <TextCom
                textAlign="center"
                color={active ? "primary" : "text"}
                weight="xl"
              >
                RESULT
              </TextCom>
            </div>
          )
        );
      },
    },
  ];

  const collapse_data = [
    {
      title: translate("detail", "Detail"),
      desc: getProductById_data?.description?.detail,
    },
    {
      title: translate("howtouse", "How to use"),
      desc: getProductById_data?.description?.howtouse,
    },
    {
      title: translate("benefits", "Benefits"),
      desc: getProductById_data?.description?.benefits,
    },
    {
      title: translate("result", "Result"),
      desc: getProductById_data?.description?.result,
    },
  ];

  let breadcrumb_data = [
    {
      name: translate("home", "Home"),
      route: "/",
    },
    {
      name: joinSpace(getProductById_data?.name),
      route: "#",
    },
  ];

  const handleRouteChange = (id: any) => {
    navigate(`/product/${id}`);
  };

  //--------- For Recent View -----------

  let initial = productStore.getProduct();

  useEffect(() => {
    let iniArr = String(initial).split(",");
    if (!iniArr?.includes(String(params?.id))) {
      let newArr = [params?.id, ...iniArr];
      if (newArr.length > 4) {
        productStore?.setProduct(newArr.slice(0, 8));
      } else {
        productStore?.setProduct(newArr);
      }
    } else {
      let newArr: any = [params?.id];
      iniArr.map((item: any) => {
        if (item !== String(params?.id)) {
          newArr.push(item);
        }
      });
      if (newArr.length > 4) {
        productStore?.setProduct(newArr.slice(0, 8));
      } else {
        productStore?.setProduct(newArr);
      }
    }
  }, [params?.id]);

  let viber: any = null;
  if (getContactUsPage_data && getContactUsPage_data?.social_links) {
    viber = getContactUsPage_data?.social_links?.filter(
      (item: any) => item?.platform === "viber"
    );
  }

  //video section
  let split_video: any = "";
  const videosrc = () => {
    if (getProductById_data?.youtube !== false) {
      if (getProductById_data?.youtube?.includes("=")) {
        split_video = getProductById_data?.youtube?.split("=")[1].split("?")[0];
        return `https://www.youtube.com/embed/${split_video}`;
      } else if (getProductById_data?.youtube?.includes("https://youtu.be/")) {
        split_video =
          getProductById_data?.youtube?.split("https://youtu.be/")[1];
        return `https://www.youtube.com/embed/${split_video}`;
      } else if (getProductById_data?.youtube?.includes("embed/")) {
        split_video = getProductById_data?.youtube?.split("embed/")[1];
        return `https://www.youtube.com/embed/${split_video}`;
      } else {
        return `https://www.youtube.com/embed/${getProductById_data?.youtube}`;
      }
    }
  };

  let play_icon = document?.querySelector(".play-icon");
  let poster = document?.querySelector(".poster");
  const vidRef: any = useRef(null);

  const handlePlayVideo = () => {
    if (vidRef.current.paused) {
      vidRef.current.play();
      play_icon?.setAttribute("style", "display: none");
      // poster?.setAttribute('style', 'display: none')
    } else {
      vidRef.current.pause();
      play_icon?.setAttribute("style", "display: block");
      // poster?.setAttribute('style', 'display: block')
    }
  };

  let recent_products: any = [];
  if (getRecentViewProduct_data) {
    recent_products = getRecentViewProduct_data?.filter(
      (data: any) => String(data?.product_id) !== String(params?.id)
    );
  }

  let deli_info = [];
  if (getProductById_data && getProductById_data?.delivery_info) {
    if (getProductById_data?.delivery_info?.includes("</br>")) {
      deli_info.push(...getProductById_data?.delivery_info?.split("</br>"));
    } else {
      deli_info.push(getProductById_data?.delivery_info);
    }
  }

  // console.log(deli_info, "dandemo")

  return (
    <>
      <BreadcrumbCom
        data={breadcrumb_data}
        isContainer={true}
        bgColor="primary_light3"
      />
      {Number(params?.id) === getProductById_data?.product_id ? (
        <StyledDetail>
          <Container className="container">
            <div className="row">
              <div className="col-lg-9 product-detail-gallery" ref={galleryRef}>
                <DetailPreview />

                {/* <Media query={{ maxWidth: 991 }}>
                  {(matches) =>
                    matches ? (
                      <>
                        {viber[0] && (
                          <a
                            href={viber[0]?.link}
                            target="_blank"
                            className="viber-con"
                          >
                            <div className="wrap d-flex">
                              <img src={Image.ViberLarge} alt="viber-icon" />
                              <TextCom
                                size="md"
                                color="light"
                                className="d-flex gap-2 align-items-center"
                              >
                                {translate("join-us-on", "Join Us On")}{" "}
                                <TextCom size="lg" weight="xl" color="light">
                                  Viber
                                </TextCom>
                              </TextCom>
                            </div>
                          </a>
                        )}
                        {getProductById_data?.delivery_info && (
                          <div className="deli-info d-flex align-items-center">
                            <TbTruckDelivery size={45} color="#5E71EC" />
                            <TextCom>
                              {getProductById_data?.delivery_info}
                            </TextCom>
                          </div>
                        )}
                      </>
                    ) : (
                      <></>
                    )
                  }
                </Media> */}

                {getProductById_data?.promotion?.length > 0 &&
                  getProductById_data?.promotion?.map((promo: any) => (
                    <CardCom className="card-con flex-column gap-3">
                      <div className="title">
                        <TextCom weight="xl">
                          {translate("promotion", "Promotion")}
                          <hr />
                        </TextCom>
                      </div>
                      <div className="d-flex promo-wrap">
                        <img
                          src={promo?.image_highlight || Image.DefaultCard}
                        ></img>
                        <div style={{ paddingLeft: "20px" }}>
                          {promo?.promotion_type === "discount" ? (
                            <TextCom as="h4" color="primary_light2" weight="xl">
                              -{" "}
                              {en2mm_num(
                                getProductById_data?.cal_discount_percent,
                                langCode
                              )}
                              <TextCom
                                as="span"
                                color="primary"
                                weight="lg"
                                size={langCode === "en" ? "lg" : "xs"}
                              >
                                %
                              </TextCom>
                            </TextCom>
                          ) : (
                            <>
                              {langCode === "en" ? (
                                <TextCom
                                  as="h4"
                                  color="primary_light2"
                                  weight="xl"
                                >
                                  Buy{" "}
                                  {en2mm_num(
                                    getProductById_data?.promotion?.[0]
                                      .buy_product?.[0]?.min_qty,
                                    langCode
                                  )}{" "}
                                  Get{" "}
                                  {en2mm_num(
                                    getProductById_data?.promotion[0]
                                      ?.reward_product[0]?.reward_qty,
                                    langCode
                                  )}
                                </TextCom>
                              ) : (
                                <TextCom
                                  as="h4"
                                  color="primary_light2"
                                  weight="xl"
                                >
                                  {en2mm_num(
                                    getProductById_data?.promotion?.[0]
                                      .buy_product?.[0]?.min_qty,
                                    langCode
                                  )} ခု ဝယ်{" "}                                 
                                  {en2mm_num(
                                    getProductById_data?.promotion[0]
                                      ?.reward_product[0]?.reward_qty,
                                    langCode
                                      )} {" "}
                                      ခု လက်ဆောင်
                                </TextCom>
                              )}
                            </>
                          )}
                          <TextCom>{promo?.description}</TextCom>
                        </div>
                      </div>
                    </CardCom>
                  ))}

                {getProductById_data?.description?.detail === false &&
                getProductById_data?.description?.howtouse === false &&
                getProductById_data?.description?.benefits === false &&
                getProductById_data?.description?.result === false ? (
                  <></>
                ) : (
                  <CardCom className="flex-column card-con desc">
                    {
                      <SkeletonWrap wrapperRef={galleryRef}>
                        <Media query={{ minWidth: 769 }}>
                          {(matches) =>
                            matches ? (
                              <>
                                <NewTabCom
                                  defaultActiveKey="detail"
                                  dataSource={dataSource}
                                />
                              </>
                            ) : (
                              <>
                                <CollapseCom defaultActiveKey="0">
                                  {collapse_data?.map((data: any, key: any) => {
                                    return (
                                      data?.desc && (
                                        <CollapsePanel
                                          header={(isActive: any) => (
                                            <TextCom weight="xl">
                                              {translate(
                                                data?.title,
                                                data?.title
                                              )}
                                            </TextCom>
                                          )}
                                          key={key.toString()}
                                        >
                                          {/* {handleNestedRenderCategory(category)} */}
                                          <TextCom>
                                            {translate(data?.desc, data?.desc)}
                                          </TextCom>
                                        </CollapsePanel>
                                      )
                                    );
                                  })}
                                </CollapseCom>
                              </>
                            )
                          }
                        </Media>
                      </SkeletonWrap>
                    }
                  </CardCom>
                )}

                {/* -------- video section -------------*/}

                {getProductById_data?.youtube !== false && (
                  <SkeletonWrap
                    wrapperRef={galleryRef}
                    style={{
                      maxHeight: "300px",
                      borderRadius: "5px",
                      marginBottom: "30px",
                    }}
                  >
                    <div className="video-con">
                      <iframe
                        src={videosrc()}
                        title=""
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    </div>
                  </SkeletonWrap>
                )}

                {getProductById_data?.youtube === false &&
                  getProductById_data?.video_link !== "" && (
                    <SkeletonWrap
                      wrapperRef={galleryRef}
                      style={{
                        maxHeight: "300px",
                        borderRadius: "5px",
                        marginBottom: "30px",
                      }}
                    >
                      <div className="video-con" onClick={handlePlayVideo}>
                        <video ref={vidRef}>
                          <source
                            src={getProductById_data?.video_link}
                            type="video/mp4"
                            alt="video"
                          />
                        </video>
                        <MdPlayCircle className="play-icon" color="white" />
                      </div>
                    </SkeletonWrap>
                  )}
              </div>
              <div className="col-lg-3 accessory-product" ref={alternativeRef}>
                {viber && viber[0] && (
                  <a
                    href={viber[0]?.link}
                    target="_blank"
                    className="viber-con"
                  >
                    <div className="wrap d-flex">
                      <img src={Image.ViberLarge} alt="viber-icon" />
                      <TextCom
                        size="md"
                        color="light"
                        className="d-flex gap-2 align-items-center"
                      >
                        {translate("join-us-on", "Join Us On")}{" "}
                        <TextCom size="lg" weight="xl" color="light">
                          Viber
                        </TextCom>
                      </TextCom>
                    </div>
                  </a>
                )}
                {getProductById_data?.delivery_info && deli_info && (
                  <div className="deli-info d-flex align-items-center ">
                    <TbTruckDelivery size={45} color="#5E71EC" />
                    <div>
                      {deli_info?.map((info: any, key: any) => (
                        <TextCom key={key} className="mb-1">
                          {info}
                        </TextCom>
                      ))}
                    </div>
                  </div>
                )}

                {getRecentViewProduct_data && recent_products?.length > 0 && (
                  <div className="recent-view mt-3">
                    <div className="title">
                      <TextCom textAlign="center" weight="xl">
                        {translate("recently-view-items", "Recently Viewed Items")}
                      </TextCom>
                    </div>
                    <ProductCardMap
                      data={recent_products?.slice(0, 5)}
                      className="card-landscape"
                    />
                  </div>
                )}
              </div>
            </div>
          </Container>
          {getProductById_data?.alternative_products?.length > 0 && (
            <Container bgColor="bg1" className="container pb-3 pt-2 mt-2">
              <Container className="mb-3 related-con">
                <TextCom size="xxxl" weight="xl" className="title">
                  {translate("related-products", "Related Products")}
                </TextCom>
                <Container bgColor="white" className="container" bgColor="bg1">
                  {getProductById_data?.alternative_products?.length < 5 ? (
                    <div className="row">
                      <ProductCardMap
                        data={getProductById_data?.alternative_products}
                        className="col-lg-3 col-6 p-card"
                      />
                    </div>
                  ) : (
                    <NewArrivalSlider
                      card_data={getProductById_data?.alternative_products}
                      slidesToShow={5}
                      no_border={false}
                      leftArrowSvg={<TbChevronLeft size={30} color="#000000" />}
                      rightArrowSvg={
                        <TbChevronRight size={30} color="#000000" />
                      }
                    />
                  )}
                </Container>
              </Container>
            </Container>
          )}
        </StyledDetail>
      ) : (
        <div style={{ minHeight: "20vh" }}></div>
      )}
    </>
  );
};
