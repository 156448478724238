import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Media from "react-media";
import { ImPlay3 } from "react-icons/im";
import { TbChevronLeft, TbChevronRight } from "react-icons/tb";

import {
  Container,
  TextCom,
  ProductCardMap,
  RSSliderCom,
  NewArrivalSlider,
  CardCom,
  ProductCard,
  SkeletonWrap,
  ButtonCom,
  Title,
  ModalCom,
} from "components";
import {
  useProduct,
  useOther,
  useHandleOther,
  useTheme,
  useHandleProduct,
} from "hook";
import { StyledHome } from "theme";
import Image from "asset/icon/pladco";
import { productStore, authStore } from "service";

type HomeProps = {};

let rsProps = {
  dots: true,
  fade: true,
  arrows: true,
  adaptiveHeight: false,
  autoplay: true,
  autoplaySpeed: 5000,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};
let rsProps1 = {
  dots: false,
  // fade: true,
  arrows: true,
  adaptiveHeight: true,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: true,
        // dots: true
      },
    },
  ],
};
let rsProps2 = {
  dots: false,
  // fade: true,
  arrows: true,
  adaptiveHeight: true,
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 7,
  responsive: [
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 4,
        infinite: true,
        // dots: true
      },
    },
  ],
};

export const Home: React.FC<HomeProps> = (props) => {
  const {
    getProductGroup_data,
    getHighLightBrandList_data,
    dispatch,
    ProductAction,
    getRecentViewProduct_data,
  } = useProduct();
  const {
    getWebsiteSlider_data,
    getWebsiteBanner_data,
    getWebsiteSliderMobile_data,
    getMetaData_data,
    OtherAction,
  } = useOther();
  const { translate, langStore } = useHandleOther();
  const { handleProductFilterChange } = useHandleProduct(props);
  const { themeContext } = useTheme();
  let langCode = langStore?.code;
  const navigate = useNavigate();
  const [state, setState] = useState({
    modalVisible: true,
  });

  //--------- for recent view -------------
  let recent_view = productStore?.getProduct();

  let arr: any = [];
  recent_view?.map((item: any) => {
    if (item !== "null") {
      arr.push(item);
    }
  });

  let view_products_ids = String(arr).split(",").join("|");

  //------------ for highlight image remember --------------
  let remember_me = authStore?.getRememberMe();

  useEffect(() => {
    dispatch(OtherAction.getWebsiteSlider({ code: "home-slider", langCode }));
    dispatch(
      OtherAction.getWebsiteSliderMobile({
        code: "home-slider-mobile",
        langCode,
      })
    );
    dispatch(ProductAction.getBrandList(""));
    dispatch(ProductAction.getHighLightBrandList());
    dispatch(OtherAction.getWebsiteBanner({ langCode }));
    dispatch(ProductAction.getRecentViewProduct(view_products_ids));
  }, [langCode]);

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };

  const handleCategoryChange = (item?: any) => {
    navigate(`/products?category_ids=${item.category_id}?page=1`, {
      replace: false,
      state: { id: item?.category_id, name: item?.name },
    });
    // window.location.reload();
    if (item?.sub_category?.length === 0) {
      onHandleChange({
        type: "category",
        key: item?.key,
        max_price: item?.price_filter_max,
      });
    }
  };

  // let rsPromotion = {
  //   dots: true,
  //   arrows: false,
  //   adaptiveHeight: true,
  //   infinite: true,
  //   slidesToShow: getPromotion_data?.length >= 2 ? 2 : 1,
  //   slidesToScroll: 1,
  //   centerMode: false,
  //   autoplay: true,
  //   responsive: [
  //     {
  //       breakpoint: 991,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //       },
  //     },
  //   ],
  // };

  let new_arrival = getProductGroup_data?.data?.filter(
    (data: any) => data?.code === "newarrival"
  )[0];
  let best_seller = getProductGroup_data?.data?.filter(
    (data: any) => data?.code === "bestseller"
  )[0];
  let latest_product = getProductGroup_data?.data?.filter(
    (data: any) => data?.code === "latestproduct"
  )[0];
  let popular_category = getProductGroup_data?.data?.filter(
    (data: any) => data?.code === "popularcategory"
  )[0];

  // isMobile?
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 768px)");
    setIsMobile(mediaQuery.matches);

    const handleResize = () => setIsMobile(mediaQuery.matches);
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let text_ref = useRef<HTMLDivElement>(null);
  window.addEventListener("scroll", (_) => {
    if (text_ref) {
      if (window.scrollY > 30) {
        if (isMobile) {
          text_ref.current?.setAttribute("style", "top: 0");
        } else {
          text_ref.current?.setAttribute("style", "top: -100%");
        }
      } else {
        text_ref.current?.setAttribute("style", "top: 0");
      }
    }
  });

  let promotion_highlight = null;
  if (getWebsiteBanner_data) {
    let img = getWebsiteBanner_data?.filter(
      (item: any) => item?.code === "promotion-highlight"
    );
    if (img) {
      promotion_highlight =
        window.innerWidth > 991
          ? img[0]?.desktop_image
          : img[0]?.mobile_image
          ? img[0]?.mobile_image
          : img[0]?.desktop_image;
    }
  }

  let banner_image = null;
  let banner_data = null;
  if (getWebsiteBanner_data) {
    let banner = getWebsiteBanner_data?.filter(
      (item: any) => item?.code === "website-banner"
    );
    if (banner) {
      banner_data = banner[0];
      banner_image =
        window.innerWidth > 991
          ? banner[0]?.desktop_image
          : banner[0]?.mobile_image
          ? banner[0]?.mobile_image
          : banner[0]?.desktop_image;
    }
  }

  const handleModal = (modalVisible: boolean) => {
    setState({
      ...state,
      modalVisible,
    });
    authStore?.setRememberMe(true);
  };

  let info_phone = "";
  if (getMetaData_data && getMetaData_data["home-info-text"]) {
    if (getMetaData_data["home-info-text"]?.text_one?.includes(`"`)) {
      info_phone =
        getMetaData_data["home-info-text"]?.text_one?.split(`"`)?.[1];
    }
  }

  return (
    <StyledHome>
      {!remember_me && promotion_highlight && (
        <ModalCom
          isModal={state.modalVisible}
          handleIsModal={handleModal}
          isCloseBtn={true}
          className="highlight-modal"
        >
          <Link
            to="/promotion"
            onClick={() => {
              authStore?.setRememberMe(true);
            }}
          >
            <img src={promotion_highlight} className="w-100" />
          </Link>
        </ModalCom>
      )}
      {getMetaData_data && getMetaData_data["highlight-text"] && (
        <div className="highlight-wrap" ref={text_ref}>
          <div className="container highlight-info d-flex justify-content-center align-items-center gap-3">
            <img
              src={getMetaData_data["highlight-text"].image}
              alt="highlight-img"
            />
            <TextCom color="light" textAlign="center">
              {getMetaData_data["highlight-text"].text_one}
            </TextCom>
          </div>
        </div>
      )}

      <Media query={{ maxWidth: 768 }}>
        {(matches) =>
          matches
            ? getWebsiteSlider_data &&
              getWebsiteSlider_data.length > 0 && (
                <Container as="section" className="hero-slider">
                  <RSSliderCom
                    rsProps={rsProps}
                    data={
                      getWebsiteSliderMobile_data &&
                      getWebsiteSliderMobile_data.length > 0 &&
                      getWebsiteSliderMobile_data?.[0].slider_images?.length > 0
                        ? getWebsiteSliderMobile_data?.[0].slider_images
                        : getWebsiteSlider_data?.[0].slider_images
                    }
                    leftArrowSvg={<TbChevronLeft size={30} color="#000000" />}
                    rightArrowSvg={<TbChevronRight size={30} color="#000000" />}
                  />
                </Container>
              )
            : getWebsiteSlider_data &&
              getWebsiteSlider_data.length > 0 && (
                <Container as="section" className="hero-slider">
                  <RSSliderCom
                    rsProps={rsProps}
                    data={
                      getWebsiteSlider_data &&
                      getWebsiteSlider_data.length > 0 &&
                      getWebsiteSlider_data?.[0].slider_images
                    }
                    leftArrowSvg={<TbChevronLeft size={30} color="#000000" />}
                    rightArrowSvg={<TbChevronRight size={30} color="#000000" />}
                  />
                </Container>
              )
        }
      </Media>

      {getMetaData_data && getMetaData_data["home-info-text"] && (
        <TextCom
          textAlign="center"
          weight="lg"
          size="xl"
          className="info-text"
          color="primary_light2"
        >
          {getMetaData_data["home-info-text"]?.text_one?.includes(`"`) ? (
            <>
              {getMetaData_data["home-info-text"]?.text_one?.split(`"`)[0]}
              <TextCom
                as="a"
                href={`tel:${info_phone}`}
                weight="lg"
                size="xl"
                color="primary_light2"
              >
                "{info_phone}"
              </TextCom>
              {getMetaData_data["home-info-text"]?.text_one?.split(`"`)[2]}
            </>
          ) : (
            getMetaData_data["home-info-text"]?.text_one
          )}
        </TextCom>
      )}

      {/* Recent View List */}
      {getRecentViewProduct_data && (
        <section className="container recent-view ">
          {getRecentViewProduct_data?.length > 0 && (
            <>
              <Title
                text={translate("recently-view-items", "Recently Viewed Items")}
              />
              <div className="row" style={{ rowGap: "20px" }}>
                <ProductCardMap
                  data={getRecentViewProduct_data}
                  className="col-lg-3 col-sm-4 col-6 p-card"
                />
              </div>
            </>
          )}
        </section>
      )}

      {/* {popular_category && popular_category?.item?.length > 0 && ( */}
      <section className="container category-slider">
        <Title text={"category"} />
        <RSSliderCom
          rsProps={rsProps1}
          leftArrowSvg={<TbChevronLeft size={30} />}
          rightArrowSvg={<TbChevronRight size={30} />}
        >
          {popular_category?.item?.map((category: any, key: any) => (
            <div
              className="category"
              onClick={() => {
                handleCategoryChange(category);
              }}
              key={key}
            >
              <img
                src={category?.image_link || Image.DefaultCard}
                alt="category-img"
                style={{objectFit: "cover"}}
              />
              <hr />
              <TextCom size="xxxxl" weight="md" textAlign="center">
                {category?.name}
              </TextCom>
            </div>
          ))}
        </RSSliderCom>
      </section>
      {/* )} */}

      {getWebsiteBanner_data && banner_image && (
        <section className="banner-section">
          <div className="container">
            <a
              href={banner_data?.weblink}
              target="_blank"
              style={{ position: "relative" }}
            >
              <div className="d-flex column-reverse justify-content-between img-con hello">
                <img
                  src={banner_image}
                  alt="banner-img"
                  style={{ width: "100%" }}
                />
                <Title text={"promotion"} class="verticle" />
              </div>
              <TextCom
                className="bg-text"
                family="inter"
                weight="xxl"
                color="primary_light1"
              >
                Chain Pro
              </TextCom>
            </a>
          </div>
        </section>
      )}

      {new_arrival && new_arrival?.type === "product.template" && (
        <>
          <section className="newarrival container group-con">
            {new_arrival?.item?.length > 0 && (
              <>
                <Title text={new_arrival?.name} />
                <div className="row" style={{ rowGap: "20px" }}>
                  <ProductCardMap
                    data={new_arrival?.item?.slice(0, 8)}
                    className="col-lg-3 col-sm-4 col-6 p-card"
                  />
                  {new_arrival?.item?.length > 8 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <ButtonCom
                        bgcolor="primary_light2"
                        onClick={() =>
                          navigate(`/product_group/${new_arrival?.code}`, {
                            state: new_arrival,
                          })
                        }
                        btnBorderRadius="15"
                      >
                        <TextCom color="light">
                          {translate("see-more", "See More")}
                        </TextCom>
                      </ButtonCom>
                    </div>
                  )}
                </div>
              </>
            )}
          </section>
        </>
      )}

      {/* {getPromotion_data &&
        getPromotion_data?.length > 0 &&
        getPromotion_data[1] && (
          <section className="banner-section right">
            <div className="container">
              <a
                style={{
                  position: "relative",
                }}
                href={`/promotion/${getPromotion_data[1].ID}`}
              >
                <Media query={{ minWidth: 992 }}>
                  {(matches) =>
                    matches ? (
                      <div className="d-flex column-reverse justify-content-between img-con">
                        <img
                          src={getPromotion_data[1]?.promotion_banner}
                          alt="banner-img"
                          style={{ width: "100%" }}
                        />
                        <Title text={"promotion"} class="verticle" />
                      </div>
                    ) : (
                      <div className="d-flex column-reverse justify-content-between img-con">
                        <img
                          src={
                            getPromotion_data[1]?.mobile_image ||
                            getPromotion_data[1]?.promotion_banner
                          }
                          alt="banner-img"
                        />
                        <Title text={"promotion"} class="verticle hello" />
                      </div>
                    )
                  }
                </Media>
                <TextCom
                  className="bg-text right"
                  family="inter"
                  weight="xxl"
                  color="primary_light1"
                >
                  Sale
                </TextCom>
              </a>
            </div>
          </section>
        )} */}

      {latest_product && latest_product?.type === "product.template" && (
        <>
          <section className="bestseller container group-con">
            {latest_product?.item?.length > 0 && (
              <>
                <Title text={latest_product?.name} />
                <div className="row" style={{ rowGap: "20px" }}>
                  <ProductCardMap
                    data={latest_product?.item?.slice(0, 8)}
                    className="col-lg-3 col-sm-4 col-6 p-card"
                  />
                  {latest_product?.item?.length > 8 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <ButtonCom
                        bgcolor="primary_light2"
                        onClick={() =>
                          navigate(`/product_group/${latest_product?.code}`, {
                            state: latest_product,
                          })
                        }
                        btnBorderRadius="15"
                      >
                        <TextCom color="light">
                          {translate("see-more", "See More")}
                        </TextCom>
                      </ButtonCom>
                    </div>
                  )}
                </div>
              </>
            )}
          </section>
        </>
      )}

      {best_seller && best_seller?.type === "product.template" && (
        <>
          <section className="bestseller container group-con">
            {best_seller?.item?.length > 0 && (
              <>
                <Title text={best_seller?.name} />
                <div className="row" style={{ rowGap: "20px" }}>
                  <ProductCardMap
                    data={best_seller?.item?.slice(0, 8)}
                    className="col-lg-3 col-sm-4 col-6 p-card"
                  />
                  {best_seller?.item?.length > 8 && (
                    <div className="d-flex justify-content-center align-items-center">
                      <ButtonCom
                        bgcolor="primary_light2"
                        onClick={() =>
                          navigate(`/product_group/${best_seller?.code}`, {
                            state: best_seller,
                          })
                        }
                        btnBorderRadius="15"
                      >
                        <TextCom color="light">
                          {translate("see-more", "See More")}
                        </TextCom>
                      </ButtonCom>
                    </div>
                  )}
                </div>
              </>
            )}
          </section>
        </>
      )}

      {getHighLightBrandList_data && getHighLightBrandList_data?.length > 0 && (
        <Container as="section" className="brand-list-sec container">
          <Title text={translate("featured-brands", "Featured Brands")} />
          <div className="d-flex flex-wrap justify-content-center">
            {getHighLightBrandList_data?.map((brand: any, key: any) => (
              <div
                className="mb-2"
                onClick={() =>
                  navigate(`/products?brand_id=${brand?.ID}?page=1`, {
                    state: { id: brand?.ID, name: brand?.name },
                  })
                }
                key={key}
              >
                {brand?.highlight_image && (
                  <img src={brand?.highlight_image} className="mb-2" />
                )}
              </div>
            ))}
          </div>
          {/* <RSSliderCom
            rsProps={rsProps2}
            data={getBrandList_data ? getBrandList_data?.all_data : ""}
            route="/products?brand_id="
            leftArrowSvg={<TbChevronLeft size={40} color="#fff" />}
            rightArrowSvg={<TbChevronRight size={40} color="#fff" />}
          /> */}
        </Container>
      )}
    </StyledHome>
  );
};
