import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Media from "react-media";
import {
  FiMenu,
  FiSearch,
  FiLayers,
  FiHome,
  FiBriefcase,
  FiTag,
  FiStar,
  FiMapPin,
  FiActivity,
  FiChevronRight,
  FiChevronLeft,
  FiShoppingCart,
} from "react-icons/fi";
import { GrClose, GrFormDown, GrFormUp } from "react-icons/gr";
import { IoMdArrowDropup } from "react-icons/io";

import {
  HeaderContainerCom,
  MobileHeaderCom,
  MobileDrawerCom,
  TextCom,
  ButtonCom,
  InputCom,
  CollapseCom,
  CollapsePanel,
  ModalCom,
  SearchCom,
} from "components";
import { PrimaryHeader } from "./primaryheader";
import { SecondaryHeader } from "./secondaryheader";
import { LangSwitch } from "./languageSwitch";
import {
  useOther,
  useAuth,
  useProduct,
  useHandleOther,
  useHandleProduct,
  useCart,
} from "hook";
import Icon from "asset/icon/pladco";
import { moneyFormat } from "utils";
import Image from "asset/icon/pladco";

type HeaderProps = {};
export const HeaderCom: React.FC<HeaderProps> = (props) => {
  const navigate = useNavigate();
  const { translate, languages, handleChangeLanguage } = useHandleOther();
  const { handleProductFilterChange } = useHandleProduct();

  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const drawerHandler = () => setDrawerOpen(!isDrawerOpen);
  const { langStore, OtherAction, dispatch, getContactUsPage_data } =
    useOther();
  const { getProfile_data, AuthAction } = useAuth();
  const {
    ProductAction,
    getProductCategory_data,
    getBrandList_data,
    getWishlist_data,
  } = useProduct();
  const { getCart_data, CartAction } = useCart();
  const langCode = langStore?.code;

  let [productName, setProductName] = useState("");
  let [search_active, setSearch_active] = useState(false);
  const open_tab = getProductCategory_data?.key?.split("-");
  const [state, setState] = useState({
    modalVisible: false,
  });

  useEffect(() => {
    dispatch(OtherAction.getDictionary());
    dispatch(ProductAction.getProductCategory({ lang: langCode }));
    dispatch(OtherAction.getContactUsPage({ lang: langCode }));
    let mega_menu = document.querySelector(".mega-menu");
    let indicator = document.querySelector(".indicator");
    if (mega_menu) mega_menu.classList.remove("right-active");
    dispatch(
      ProductAction.getProductGroup(
        "newarrival|bestseller|latestproduct|popularcategory"
      )
    );
    dispatch(OtherAction.getMetaData({ langCode }));
    dispatch(CartAction.getCart());
    // if (indicator) indicator.setAttribute('style', `display:none`)
  }, [langCode]);

  const handleCheck = (e: any, value: any) => {
    handleChangeLanguage(value);
    e.target.classList.toggle("active");
    setDrawerOpen(false);
  };

  const HandleSignOut = async () => {
    await dispatch(AuthAction.signOut());
    navigate("/");
  };

  const handleSearch = (e: any) => {
    productName !== "" && navigate(`/search/${encodeURI(productName)}`);
    setSearch_active(!search_active);
    setProductName("");
    setDrawerOpen(false);
  };

  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };

  const handleCategoryChange = (item?: any) => {
    if (item?.item?.sub_category?.length === 0) {
      onHandleChange({ type: "category", key: item?.item?.key });
    }
  };

  const handleClick = (cate: any, sub: any) => {
    if (sub?.sub_category?.length <= 0) {
      navigate(`products?category_ids=${sub?.category_id}?page=1`, {
        state: {
          main_id: cate?.category_id,
          main_name: cate?.category_name,
          id: sub?.category_id,
          name: sub?.category_name,
        },
      });
      setDrawerOpen(false);
    }
  };

  const handleNestedRenderCategory = (category: any) => {
    return (
      category?.sub_category?.length > 0 && (
        <CollapseCom
          accordion
          expandIcon={({ isActive }: any) =>
            isActive ? <GrFormUp size={20} /> : <GrFormDown size={20} />
          }
          handleChange={handleCategoryChange}
          // defaultActiveKey={[open_tab?.length > 2 ? open_tab?.[1] : '']}
        >
          {category?.sub_category?.map((subcategory: any, index: any) => (
            <CollapsePanel
              header={() => (
                <TextCom
                  color={
                    getProductCategory_data?.selected_ids ==
                    subcategory?.category_id
                      ? "primary"
                      : "text"
                  }
                  onClick={() => handleClick(category, subcategory)}
                >
                  {subcategory?.category_name}
                </TextCom>
              )}
              item={subcategory}
              key={index.toString()}
            >
              {handleNestedRenderCategory(subcategory)}
            </CollapsePanel>
          ))}
        </CollapseCom>
      )
    );
  };

  // const handleNestedRenderCategory = (category: any) => (
  //   category?.sub_category?.length > 0 &&
  //   <CollapseCom accordion handleChange={handleCollapseChange}>
  //     {category?.sub_category?.map((subcategory: any, key: any) => (
  //       <CollapsePanel header={subcategory?.category_name} item={subcategory} key={key} onClick={() => navigate(`../category/${category?.category_id}/product/${subcategory.category_id}`)}>
  //         {handleNestedRenderCategory(subcategory)}
  //       </CollapsePanel>
  //     ))}
  //   </CollapseCom>
  // )

  //---------------------Drawer---------------------------
  let [drawerType, setDrawerType] = useState({
    type: "main",
    content: [],
  });

  let drawer_content = [
    {
      name: "product",
      // icon: <FiLayers size={20} />,
      hasInner: true,
      drawer: "product",
      content: getProductCategory_data ? getProductCategory_data?.data : [],
      link: "#",
    },
    {
      name: "brand",
      // icon: <FiLayers size={20} />,
      hasInner: true,
      drawer: "brand",
      content: getBrandList_data ? getBrandList_data?.all_data : [],
      link: "#",
    },
    {
      name: "promotion",
      // icon: <FiBriefcase size={20} />,
      link: "promotion",
    },
    {
      name: "order-history",
      // icon: <FiBriefcase size={20} />,
      link: "user/my_orders",
    },
    {
      name: "why-chainpro",
      // icon: <FiBriefcase size={20} />,
      link: "/aboutus",
    },
    {
      name: "chainpro-care",
      // icon: <FiLayers size={20} />,
      hasInner: true,
      drawer: "chainpro-care",
      content: [
        { name: "faq", link: "/faq" },
        { name: "terms-and-cons", link: "/page/terms_and_condition" },
        { name: "privacy-policy", link: "/page/privacy_policy" },
        { name: "how-to-register", link: "/page/how_to_register" },
        { name: "how-to-buy", link: "/page/how_to_buy" },
      ],
      link: "#",
    },
    {
      name: "contact-us",
      // icon: <FiBriefcase size={20} />,
      link: "contactus",
    },
  ];

  const handleDrawerType = (type: any, content: any) => {
    setDrawerType({
      ...drawerType,
      type: type,
      content: content,
    });
    type === "main" && setDrawerOpen(false);
  };

  const handleChangeRoute = (id: any, name: any) => {
    navigate(`/products?brand_id=${id}?page=1`, { state: { id: id, name: name } });
    setDrawerOpen(false);
  };

  let user_icon = document.querySelector(".user");
  let user_menu = document.querySelector(".user-menu");
  user_icon?.addEventListener("mouseover", () => {
    user_menu?.classList.add("active");
  });
  user_icon?.addEventListener("mouseout", () => {
    user_menu?.classList.remove("active");
  });

  const handleCLick = (route: any) => {
    navigate(route);
    user_menu?.classList.remove("active");
  };

  const handleWishlist = () => {
    if (getProfile_data) {
      navigate(`/user/wishlist`);
    } else {
      handleModal(true);
    }
  };

  const handleModal = (modalVisible: boolean) => {
    setState({
      ...state,
      modalVisible,
    });
  };

  let [showcart, setShowcart] = useState(false);
  let [showprofile, setshowProfile] = useState(false);

  const location = useLocation();
  
  let phone: any = null;
  if (getContactUsPage_data && getContactUsPage_data?.branches) {
    phone = getContactUsPage_data?.branches[0]?.phone.split(",");
  }

  return (
    <HeaderContainerCom>
      <Media query={{ minWidth: 992 }}>
        {(matches) =>
          matches ? (
            <>
              <PrimaryHeader {...props} />
              {/* <SecondaryHeader {...props} /> */}
            </>
          ) : (
            <>
              <MobileHeaderCom>
                <div className="container">
                  <div className="mb_header_content">
                    <div className="left_con">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => (window.location.href = "/")}
                      >
                        <img
                          className="img-fluid"
                          src={getContactUsPage_data?.logo1}
                          alt="logo"
                        />
                      </div>
                    </div>
                    <div className="right_con">
                      <div className="user">
                        <img
                          src={Icon.User}
                          alt="user-icon"
                          className="profile-icon"
                          onClick={() => {
                            getProfile_data && navigate("/user/profile");
                            // setshowProfile(!showprofile);
                          }}
                        />
                        {/* {showprofile && ( */}
                        <div className="user-dropdown">
                          {getProfile_data?.name ? (
                            <ul className="user">
                              <li>
                                <Link to={"/user/profile"} className="d-flex">
                                  <img src={Icon.User} alt="user-icon" />
                                  <TextCom>{getProfile_data?.name}</TextCom>
                                </Link>
                              </li>
                              <li>
                                <Link to={"/user/wishlist"} className="d-flex">
                                  <img
                                    src={Icon.WishlistInfo}
                                    alt="user-icon"
                                  />
                                  <TextCom>
                                    {translate("my-wishlist", "Wishlist")}
                                  </TextCom>
                                </Link>
                              </li>
                              <li>
                                <Link to={"/user/my_orders"} className="d-flex">
                                  <img src={Icon.fileIcon} alt="order" />
                                  <TextCom>
                                    {translate(
                                      "order-history",
                                      "Order History"
                                    )}
                                  </TextCom>
                                </Link>
                              </li>
                              {/* <li>
                                  <div onClick={HandleSignOut} className="d-flex">
                                    <img src={Icon.logoutIcon} alt="order" />
                                    <TextCom>
                                      {translate("logout", "Logout")}
                                    </TextCom>
                                  </div>
                                </li> */}
                            </ul>
                          ) : (
                            <ul className="non-user">
                              <li>
                                <Link to={"/user/signin"}>
                                  <TextCom>
                                    {translate("login", "Login")}
                                  </TextCom>
                                </Link>
                              </li>
                              <li>
                                <Link to={"/user/signup"}>
                                  <TextCom>
                                    {translate("signup", "Sign Up")}
                                  </TextCom>
                                </Link>
                              </li>
                            </ul>
                          )}
                        </div>
                        {/* )} */}
                      </div>
                      <div
                        className="cart"
                        onClick={() => {
                          getCart_data?.order_lines?.length === 0 &&
                            navigate("/order/cart");
                        }}
                      >
                        <img src={Icon.Cart} alt="cart-icon" />
                        {/* <Link to="/order/cart">
                        </Link> */}
                        {getCart_data?.order_lines?.length > 0 ? (
                          <div className="cart-badge">
                            <TextCom color="light" size="sm">
                              {getCart_data?.cart_item_count > 999
                                ? moneyFormat(Math.ceil(999), langCode) + "+"
                                : getCart_data?.cart_item_count}
                            </TextCom>
                          </div>
                        ) : (
                          <div className="cart-badge">
                            <TextCom color="light" size="sm">
                              0
                            </TextCom>
                          </div>
                        )}
                        {getCart_data &&
                          getCart_data?.order_lines?.length > 0 && (
                            <div
                              className="cart-popup"
                              // style={
                              //   showcart
                              //     ? { display: "block" }
                              //     : { display: "none" }
                              // }
                            >
                              <IoMdArrowDropup className="arrow" />
                              <ul className="wrap">
                                {getCart_data?.order_lines?.map(
                                  (item: any, key: any) => (
                                    <li key={key}>
                                      <div className="d-flex gap-2 product-line">
                                        {item?.is_delivery_line === true ? (
                                          <img
                                            src={Image.Delivery}
                                            className="deli-image"
                                          />
                                        ) : (
                                          <img
                                            src={
                                              item?.product_image ||
                                              Icon.DefaultCard
                                            }
                                          />
                                        )}
                                        <div>
                                          <TextCom size="sm" weight="lg">
                                            {item?.name}
                                          </TextCom>
                                          <TextCom size="sm">
                                            {translate("qty", "Qty")} :{" "}
                                            <TextCom as="span" weight="lg">
                                              {moneyFormat(
                                                item?.quantity,
                                                langCode
                                              )}
                                            </TextCom>
                                          </TextCom>
                                          <TextCom
                                            size="sm"
                                            weight="lg"
                                            color="primary_light2"
                                          >
                                            <TextCom
                                              as="span"
                                              weight="lg"
                                              color="primary"
                                            >
                                              {moneyFormat(
                                                item?.price_subtotal,
                                                langCode
                                              )}
                                            </TextCom>{" "}
                                            {translate("ks", "Ks")}
                                          </TextCom>
                                        </div>
                                      </div>
                                      {item?.promotion_lines && (
                                        <div className="d-flex promo gap-2">
                                          <img
                                            src={
                                              item?.promotion_lines[0]
                                                ?.product_image
                                                ? item?.promotion_lines[0]
                                                    ?.product_image
                                                : item?.promotion_lines[0]
                                                    ?.reward_type === "discount"
                                                ? Icon.PromotionTag
                                                : Icon.DefaultCard
                                            }
                                            className={
                                              item?.promotion_lines[0]
                                                ?.reward_type === "discount"
                                                ? "discount"
                                                : ""
                                            }
                                          />
                                          <div>
                                            <TextCom size="sm" weight="lg">
                                              {item?.promotion_lines[0]?.name}
                                            </TextCom>
                                            <TextCom
                                              size="sm"
                                              weight="lg"
                                              color="primary_light2"
                                            >
                                              {item?.promotion_lines[0]
                                                ?.price_subtotal === 0 ? (
                                                <TextCom
                                                  as="span"
                                                  size="xs"
                                                  weight="lg"
                                                  className="free"
                                                >
                                                  {translate("free", "Free")}
                                                </TextCom>
                                              ) : (
                                                <>
                                                  <TextCom
                                                    as="span"
                                                    weight="lg"
                                                    color="primary"
                                                  >
                                                    {moneyFormat(
                                                      item?.promotion_lines[0]
                                                        ?.price_subtotal,
                                                      langCode
                                                    )}
                                                  </TextCom>{" "}
                                                  {translate("ks", "Ks")}
                                                </>
                                              )}
                                            </TextCom>
                                          </div>
                                        </div>
                                      )}
                                    </li>
                                  )
                                )}
                              </ul>
                              <div className="total">
                                <div className="d-flex justify-content-between align-items-center">
                                  <TextCom size="sm" weight="lg">
                                    {translate("cart-total", "Cart Total")}
                                  </TextCom>
                                  <TextCom size="sm" weight="lg">
                                    <TextCom as="span" weight="lg">
                                      {getCart_data
                                        ? moneyFormat(
                                            Math.ceil(getCart_data?.total),
                                            langCode
                                          )
                                        : moneyFormat(0, langCode)}
                                    </TextCom>{" "}
                                    {translate("ks", "Ks")}
                                  </TextCom>
                                </div>
                                <ButtonCom
                                  text={translate("go-to-cart", "Go To Cart")}
                                  bgcolor="primary_light2"
                                  onClick={() => {
                                    navigate("/order/cart");
                                  }}
                                />
                              </div>
                            </div>
                          )}
                      </div>
                      <div className="wishlist">
                        <div
                          className="d-flex flex-column justify-content-center align-items-center"
                          onClick={handleWishlist}
                        >
                          <img src={Icon.Active_wishlistheart} />
                        </div>
                        <div
                          className="wish-badge"
                          style={
                            langStore?.code === "my" ? { right: "3px" } : {}
                          }
                        >
                          <TextCom color="light" size="xs" className="">
                            {getWishlist_data ? getWishlist_data?.length : 0}
                          </TextCom>
                        </div>
                        <ModalCom
                          isModal={state.modalVisible}
                          handleIsModal={handleModal}
                          isCloseBtn={true}
                        >
                          <div style={{ position: "relative" }}>
                            <div
                              style={{
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                marginTop: 6,
                                paddingLeft: 10,
                                paddingRight: 10,
                              }}
                            >
                              <img
                                className="d-flex justify-content-center align-items-center m-auto mb-3"
                                src={Icon.WishlistInfo}
                                alt="wishlist-info"
                              />
                              <TextCom
                                style={{
                                  textAlign: "center",
                                  marginBottom: 25,
                                }}
                              >
                                {translate(
                                  "please-login-to-get-wishlist",
                                  "Please Login to get Wishlist"
                                )}
                              </TextCom>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  textTransform: "uppercase",
                                }}
                              >
                                <ButtonCom
                                  text="Login"
                                  bgColor="dark"
                                  color="light"
                                  btnBorderRadius="xxxs"
                                  onClick={() =>
                                    handleRouteChange("/user/signin")
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </ModalCom>
                      </div>
                      <FiMenu
                        size={27}
                        className="menu_icon"
                        onClick={drawerHandler}
                        color="#333339"
                      />
                    </div>
                  </div>
                </div>
              </MobileHeaderCom>
              <MobileDrawerCom
                isOpen={isDrawerOpen}
                drawerHandler={drawerHandler}
              >
                <div className="mobile-drawer main">
                  <div className="head d-flex justify-content-between">
                    {drawerType.type === "main" ? (
                      <SearchCom setDrawer={setDrawerOpen} />
                    ) : (
                      <div className="d-flex align-items-center sub-drawer">
                        <FiChevronLeft
                          size={20}
                          onClick={(e) =>
                            setDrawerType({ ...drawerType, type: "main" })
                          }
                        />
                        <TextCom size="xl" weight="lg">
                          {translate(drawerType.type, drawerType.type)}
                        </TextCom>
                      </div>
                    )}
                    <GrClose
                      size={23}
                      onClick={drawerHandler}
                      className="close-icon"
                    />
                  </div>
                  <div className="content">
                    {drawerType.type === "main" ? (
                      <>
                        <ul>
                          {drawer_content.map((content, key) => (
                            <li key={key}>
                              <Link
                                className="d-flex justify-content-between align-items-center"
                                to={content?.link ? content?.link : "#"}
                                onClick={(e) =>
                                  handleDrawerType(
                                    content?.drawer ? content?.drawer : "main",
                                    content?.content
                                  )
                                }
                              >
                                <div className="d-flex">
                                  {content.icon}
                                  <TextCom weight="lg">
                                    {translate(content.name, content.name)}
                                  </TextCom>
                                </div>
                                {content?.content && <FiChevronRight />}
                              </Link>
                            </li>
                          ))}
                        </ul>
                        {/* <hr /> */}
                        {/* <hr /> */}
                        <div className="call d-flex">
                          {/* <img src={Icon.hotlineBlack} alt="hotline" /> */}
                          {getContactUsPage_data && (
                            <>
                              <TextCom color="primary" size="lg" weight="lg">
                                {translate("call-us", "Call Us")}:{" "}
                                {getContactUsPage_data?.hotline ? (
                                  <TextCom
                                    as="a"
                                    href={`tel: ${getContactUsPage_data?.hotline}`}
                                  >
                                    {getContactUsPage_data?.hotline}
                                  </TextCom>
                                ) : (
                                  <>
                                    {phone && (
                                      <TextCom as="a" href={`tel:${phone[0]}`}>
                                        {phone[0]}
                                      </TextCom>
                                    )}
                                  </>
                                )}
                              </TextCom>
                            </>
                          )}
                        </div>
                        <LangSwitch setDrawer={setDrawerOpen} />
                      </>
                    ) : (
                      <>
                        {drawerType?.content?.length > 0 &&
                        drawerType?.type === "product" ? (
                          <CollapseCom
                            expandIcon={({ isActive }: any) =>
                              isActive ? (
                                <GrFormUp size={20} />
                              ) : (
                                <GrFormDown size={20} />
                              )
                            }
                          >
                            {drawerType?.content?.map(
                              (category: any, key: any) => (
                                <CollapsePanel
                                  header={(isActive: any) => (
                                    <TextCom
                                      weight="lg"
                                      color={
                                        getProductCategory_data?.selected_ids ==
                                        category?.category_id
                                          ? "primary"
                                          : "text"
                                      }
                                    >
                                      {category?.category_name}
                                    </TextCom>
                                  )}
                                  item={category}
                                  key={key}
                                >
                                  {handleNestedRenderCategory(category)}
                                </CollapsePanel>
                              )
                            )}
                          </CollapseCom>
                        ) : drawerType?.type === "brand" ? (
                          <div className="brand">
                            {drawerType?.content.map(
                              (content: any, key) =>
                                content?.image !== false && (
                                  <img
                                    src={content?.image}
                                    onClick={() =>
                                      handleChangeRoute(
                                        content.ID,
                                        content.name
                                      )
                                    }
                                    key={key}
                                    className={
                                      Number(
                                        location?.search?.split("=")?.[1]?.split("?")[0] ||
                                          location?.state?.id
                                      ) === Number(content.ID)
                                        ? "active"
                                        : ""
                                    }
                                  />
                                )
                            )}
                          </div>
                        ) : (
                          <div className="chain-pro d-flex flex-column gap-3">
                            {drawerType?.content.map((content: any, key) => (
                              <TextCom
                                as="a"
                                href={content.link}
                                weight="lg"
                                key={key}
                              >
                                {translate(content.name, content.name)}
                              </TextCom>
                            ))}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </MobileDrawerCom>
            </>
          )
        }
      </Media>
    </HeaderContainerCom>
  );
};
