import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ReactImageMagnify from "react-image-magnify";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { TiArrowSortedDown } from "react-icons/ti";
import { StyledRSSlider } from "theme";
import { TextCom, CardCom } from "components";
import Image from "asset/icon/pladco";
import { useProduct, useHandleOther } from "hook";
import { en2mm_num } from "utils";

type RSSliderProps = {
  children?: any;
  rimProps?: object;
  rsProps?: object;
  type?: string;
  data?: any[];
  innerRef?: any;
  leftArrowSvg?: any;
  rightArrowSvg?: any;
  renderLeftArrow?: any;
  renderRightArrow?: any;
  renderContent?: any;
  asNavFor?: any;
  className?: any;
  route?: any;
  productslide?: boolean;
  calculated_price?: any;
};
export const RSSliderCom: React.FC<RSSliderProps> = ({
  children,
  rimProps,
  rsProps,
  type,
  data = [],
  innerRef,
  leftArrowSvg,
  rightArrowSvg,
  renderLeftArrow,
  renderRightArrow,
  renderContent,
  route,
  productslide,
  calculated_price,
  ...props
}) => {
  const [hoverSrc, setHoverSrc] = useState(false);
  const { getProductById_data } = useProduct();
  const { translate, langStore } = useHandleOther();
  const langCode = langStore?.code;
  let img_media = ["500w", "779w", "1020w", "1200w", "1426w"];
  let dataSource: any =
    data?.length > 0 &&
    data.map((x) => ({
      ...x,
      srcSet: img_media
        .map((y) => `${x.image || Image.DefaultCard} ${y}`)
        .join(", "),
      small:
        x.image ||
        x.promotion_highlight ||
        x.highlight_image ||
        Image.DefaultCard,
      large:
        x.image ||
        x.promotion_highlight ||
        x.highlight_image ||
        Image.DefaultCard,
      highlighttext: x?.caption,
      link: x?.link,
    }));

  const navigate = useNavigate();

  const NextArrow = (props: any) => {
    const { className, onClick } = props;
    return renderRightArrow ? (
      renderRightArrow(props)
    ) : (
      <div
        className={`next ${
          className.includes("slick-disabled") ? "disabled" : undefined
        }`}
        onClick={onClick}
      >
        {rightArrowSvg || <img src={require("asset/icon/pladco/right.png")} />}
      </div>
    );
  };

  const PrevArrow = (props: any) => {
    const { className, onClick } = props;
    return renderLeftArrow ? (
      renderLeftArrow(props)
    ) : (
      <div
        className={`prev ${
          className.includes("slick-disabled") ? "disabled" : undefined
        }`}
        onClick={onClick}
      >
        {leftArrowSvg || <img src={require("asset/icon/pladco/left.png")} />}
      </div>
    );
  };

  let RSProps = {
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    pauseOnHover: true,
    ...rsProps,
  };

  let RIMProps = (src: any) => ({
    smallImage: {
      alt: "",
      isFluidWidth: true,
      src: src.small,
      srcSet: src.srcSet,
      // sizes: '(max-width: 480px) 100vw, (max-width: 1200px) 30vw, 360px',
    },
    largeImage: {
      src: src.large,
      width: 826,
      height: 800,
    },
    lensStyle: { backgroundColor: "rgba(0,0,0,.6)" },
    ...rimProps,
  });

  const handleRoute = (route: any, data: any, name?: any) => {
    if (route) {
      if (data) {
        navigate(`${route + data}`, { state: { id: data, name: name } });
      } else {
        navigate(route);
      }
    }
  };

  const location = useLocation();

  return (
    <div className="rsslider-con" style={{ position: "relative" }}>
      <StyledRSSlider
        className="cu-slide"
        {...RSProps}
        {...props}
        ref={innerRef}
      >
        {children
          ? children
          : dataSource?.length > 0 &&
            dataSource.map((src: any, index: any) => (
              <div
                onMouseMove={(e) => setHoverSrc(type === "zoom" ? src : false)}
                key={index}
                onClick={(e) => {
                  // console.log(src.link);
                  src.link
                    ? window.open(src.link, "_blank")
                    : handleRoute(route, src.ID, src?.name);
                }}
                className={location?.state?.id === src.ID ? `active` : ``}
              >
                <img style={{ width: "100%" }} src={src.large} />
                <div className="highlight-text">
                  <TextCom
                    as="h1"
                    size="xxxxxl"
                    textAlign="center"
                    color="light"
                    weight="xl"
                  >
                    {src?.highlighttext}
                  </TextCom>
                  {/* <TextCom color="light" textAlign="center">
                      Lorem ipsum dolor sit amet, consetetur sadipscing elitr,
                      sed diam nonumy eirmod tempor invidunt ut labore et dolore
                      magna aliquyam erat, sed diam voluptua. At vero eos et
                      accusam et justo duo dolores et ea rebum. Stet clita kasd
                      gubergren, no sea takimata sanctus est Lorem ipsum dolor
                      sit amet. Lorem ipsum dolor sit amet, consetetur
                      sadipscing elitr, sed diam nonumy eirmod tempor invidunt
                      ut labore et dolore magna aliquyam erat, sed diam
                      voluptua. At vero eos et accusam et justo duo dolores et
                      ea rebum.
                    </TextCom> */}
                </div>
                {renderContent && renderContent(src, index)}
              </div>
            ))}
      </StyledRSSlider>
      {hoverSrc && (
        <div
          onMouseLeave={(_) => setHoverSrc(false)}
          className="hello"
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 1000,
          }}
        >
          <ReactImageMagnify {...RIMProps(hoverSrc)} {...props} />
        </div>
      )}

      {productslide === true ? (
        getProductById_data &&
        getProductById_data?.promotion[0]?.promotion_type === "discount" ? (
          <div className="display-center percent-dis">
            <TextCom as="h4" color="light" size="lg">
              {/* savepoint */}
              {/* {calculated_price?.discountamount}
              {calculated_price?.discounttype == "percent"
                ? "%"
                : " " + translate("ks", "Ks")} */}
              {en2mm_num(getProductById_data?.cal_discount_percent, langCode)}
              <TextCom
                as="span"
                color="light"
                size={langCode === "en" ? "lg" : "xs"}
              >
                %
              </TextCom>
            </TextCom>
          </div>
        ) : (
          getProductById_data?.promotion[0]?.promotion_type === "product" && (
            <CardCom className="gift-main-con" bgColor="status">
              {getProductById_data?.promotion[0]?.promotion_type ===
                "product" &&
              getProductById_data?.product_id !==
                getProductById_data?.promotion[0].reward_product[0]
                  ?.product_template_id ? (
                <>
                  {!hoverSrc && (
                    <div className="gift-text">
                      {langCode === "en" ? (
                        <TextCom
                          as="h6"
                          textAlign="center"
                          color="white"
                          className="display-center"
                          weight="sm"
                        >
                          Buy{" "}
                          {en2mm_num(
                            getProductById_data?.promotion?.[0].buy_product?.[0]
                              ?.min_qty,
                            langCode
                          )}{" "}
                          {
                            getProductById_data?.promotion[0]?.buy_product[0]
                              ?.uom_name
                          }{" "}
                          Get{" "}
                          {en2mm_num(
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.reward_qty,
                            langCode
                          )}{" "}
                          {
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.get_uom_name
                          }
                        </TextCom>
                      ) : (
                        <TextCom
                          as="h6"
                          textAlign="center"
                          color="white"
                          className="display-center"
                          weight="sm"
                        >
                          {en2mm_num(
                            getProductById_data?.promotion[0].buy_product?.[0]
                              ?.min_qty,
                            langCode
                          )}{" "}
                          {
                            getProductById_data?.promotion[0]?.buy_product[0]
                              ?.uom_name
                          }
                          ဝယ်{" "}
                          {en2mm_num(
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.reward_qty,
                            langCode
                          )}{" "}
                          {
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.get_uom_name
                          }{" "}
                          လက်ဆောင်
                        </TextCom>
                      )}
                      <div className="free-gift">
                        <img
                          src={
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.reward_image || Image.DefaultDetailCard
                          }
                          alt="logo"
                        />
                        <TextCom
                          as="h4"
                          className="free-item"
                          color="light"
                          size="xs"
                        >
                          +
                          {en2mm_num(
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.reward_qty,
                            langCode
                          )}{" "}
                          {
                            getProductById_data?.promotion[0]?.reward_product[0]
                              ?.get_uom_name
                          }
                        </TextCom>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {langCode !== "en" ? (
                    <TextCom
                      as="h5"
                      textAlign="center"
                      weight="sm"
                      color="white"
                      className="d-flex justify-content-center align-items-center w-100"
                    >
                      {en2mm_num(
                        getProductById_data?.promotion[0]?.buy_product[0]
                          ?.min_qty,
                        langCode
                      )}{" "}
                      {
                        getProductById_data?.promotion[0]?.buy_product[0]
                          ?.uom_name
                      }{" "}
                      ဝယ်{" "}
                      {en2mm_num(
                        getProductById_data?.promotion[0]?.reward_product[0]
                          ?.reward_qty,
                        langCode
                      )}{" "}
                      {
                        getProductById_data?.promotion[0]?.reward_product[0]
                          ?.get_uom_name
                      }{" "}
                      လက်ဆောင်
                    </TextCom>
                  ) : (
                    <TextCom
                      as="h5"
                      textAlign="center"
                      weight="sm"
                      color="white"
                      className="d-flex justify-content-center align-items-center w-100"
                    >
                      {translate("buy", "Buy")}{" "}
                      {en2mm_num(
                        getProductById_data?.promotion[0]?.buy_product[0]
                          ?.min_qty,
                        langCode
                      )}{" "}
                      {
                        getProductById_data?.promotion[0]?.buy_product[0]
                          ?.uom_name
                      }{" "}
                      {translate("get", "Get")}{" "}
                      {en2mm_num(
                        getProductById_data?.promotion[0]?.reward_product[0]
                          ?.reward_qty,
                        langCode
                      )}{" "}
                      {
                        getProductById_data?.promotion[0]?.reward_product[0]
                          ?.get_uom_name
                      }
                    </TextCom>
                  )}
                </>
              )}
            </CardCom>
          )
        )
      ) : (
        <></>
      )}
    </div>
  );
};

RSSliderCom.defaultProps = {
  data: [],
  type: "simple",
};
