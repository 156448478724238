import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";

import { BsExclamationCircle } from "react-icons/bs";
import { FaPhoneAlt, FaMapMarkerAlt } from "react-icons/fa";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { RiDeleteBin7Line } from "react-icons/ri";
import { AiOutlinePlus } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";

import {
  useHandleOther,
  useCart,
  useHandleCart,
  useAuth,
  useOther,
} from "hook";
import { TextCom, ModalCom, ButtonCom } from "components";
import { ShippingForm } from "./shippingform";
import { StyleCheckout1 } from "theme";

type checkoutProps = {
  guestForm?: any | undefined;
};

export const CheckOut: React.FC<checkoutProps> = (props) => {
  let pickup_price = 50000;
  const navigate = useNavigate();
  const location = useLocation();
  const { translate, langStore } = useHandleOther();
  const { getMetaData_data, getContactUsPage_data } = useOther();
  const {
    handleDeleteShippingAddress,
    handlePayNow,
    handleSelectShipping,
    handleEditShippingAddress,
    handleCreateShippingAddress,
  } = useHandleCart();
  const {
    getShippingAddress_data,
    getShippingAddressByCode_data,
    getPaymentList_data,
    getCart_data,
    createCart_data,
    getOrder_data,
    dispatch,
    CartAction,
    selectShippingAddress_data,
    createShippingAddress_data,
    payNow_data,
  } = useCart();
  const { getProfile_data, signIn_data } = useAuth();
  const langCode = langStore?.code;
  const [guestForm, setguestForm]: Array<any> = useState([]);
  const [delitype, setDeliType] = useState("");
  const [show, setShow] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [from, setFrom] = useState("");
  const [submitAddress, setSubmitaddress]: any = useState({
    key: "",
    radioPicked: "",
  });
  const [alertAddressDelete, setalertAddressDelete] = useState(false);
  const [orderfail, setorderFail] = useState(false);
  const [ID, setid] = useState(0);
  let [selectedAdd, setSelectedAdd]: any = useState(null);

  useEffect(() => {
    dispatch(CartAction.getCart());
    dispatch(CartAction.getShippingAddress());
    dispatch(CartAction.getShippingLocations("MM"));
    dispatch(CartAction.getShippingAddressByCode("pickup"));
  }, []);

  useEffect(() => {
    if (getCart_data?.total < pickup_price) {
      setDeliType("pickup");
    }
    // if (delitype === "pickup") {
    //   setSelectedAdd(getShippingAddressByCode_data);
    // } else {
    //   setSelectedAdd(null);
    // }
  }, [delitype,getCart_data]);

  const handleModal = async (id?: number, from?: any) => {
    setFrom(from);
    if (from === "edit") {
      // await dispatch(CartAction.getShippingAddressById(id))
      await handleSelectShipping({ id: id });
      setSubmitaddress({ ...submitAddress, key: id });
    }
    setIsModal(!isModal);
  };

  const onHandleDelete = async (from?: any, id?: any) => {
    if (from === "delete" && id) {
      setid(id);
      setalertAddressDelete((prev) => !prev);
    } else {
      handleDeleteShippingAddress(ID);
      setalertAddressDelete((prev) => !prev);
    }
  };

  const handleNext = (add?: any) => {
    if (add !== null) {
      navigate("/order/payment", {
        state: {
          address: add,
          type:
            getCart_data?.total < pickup_price || delitype === "pickup"
              ? "pickup"
              : "delivery",
        },
      });
    } else {
      window.scroll(0, 0);
      setShow(true);
      setTimeout(() => {
        setShow(false);
      }, 3000);
    }
  };

  let mobile = [];
  if (getShippingAddressByCode_data && getShippingAddressByCode_data?.mobile) {
    if (getShippingAddressByCode_data?.mobile?.includes(",")) {
      mobile = getShippingAddressByCode_data?.mobile?.split(",");
    } else {
      mobile.push(getShippingAddressByCode_data?.mobile);
    }
  }

  return (
    <StyleCheckout1>
      <div className="container wrapper">
        <div className="row">
          <div className="info-text">
            {getMetaData_data && getMetaData_data["shipping-info-text"] && (
              <TextCom color="paragraph" className="mb-3" weight="lg" size="lg">
                {getMetaData_data["shipping-info-text"].text_one}
              </TextCom>
            )}
          </div>

          {getCart_data?.total >= pickup_price && (
            <TextCom color="danger" size="md" weight="md">
              * ဝန်ဆောင်မှုအား ရွေးချယ်ပါ။
            </TextCom>
          )}

          {/* <div className="d-flex "></div> */}
          <div
            className="col-lg-6 col-12 d-flex gap-3 align-items-center title-wrap"
            onClick={() =>
              getCart_data?.total >= pickup_price && setDeliType("pickup")
            }
          >
            <div className={`myradio ${delitype === "pickup" ? "active" : ""}`}>
              <div className="inner"></div>
            </div>
            <TextCom size="xl" weight="lg" color="paragraph">
              ChainPro ဆိုင်တွင် လာရောက်ယူဆောင်မည်
            </TextCom>
          </div>
          <div
            className="col-lg-6 col-12 d-flex gap-3 align-items-center title-wrap"
            style={
              getCart_data?.total < pickup_price
                ? { opacity: 0, visibility: "hidden" }
                : { opacity: 1, visibility: "visible" }
            }
            onClick={() =>
              getCart_data?.total >= pickup_price && setDeliType("deli")
            }
          >
            <div className={`myradio ${delitype === "deli" ? "active" : ""}`}>
              <div className="inner"></div>
            </div>
            <TextCom size="xl" weight="lg" color="paragraph">
              အိမ်တိုင်ရာရောက် ပို့ဆောင်ရန်
            </TextCom>
          </div>
          {/* ---- Pickup ----*/}
          <div
            // className={
            //   getCart_data?.total >= pickup_price ? "col-lg-6" : "col-lg-9"
            // }
            className="col-lg-6"
          >
            <div
              // className={`pickup-con ${delitype === "pickup" ? "active" : ""}`}
              className={`pickup-con`}
              // onClick={() =>
              //   getCart_data?.total >= pickup_price && setDeliType("pickup")
              // }
            >
              {/* <div
                className="disable-layer"
                style={
                  delitype === "deli"
                    ? { display: "block" }
                    : { display: "none" }
                }
              ></div> */}
              {/* <div className="d-flex gap-3 align-items-center title-wrap">
                <TextCom
                  as="input"
                  type="radio"
                  value=""
                  name="add"
                  checked={delitype === "pickup" ? true : false}
                  style={
                    getCart_data?.total < pickup_price
                      ? { display: "none" }
                      : { display: "block" }
                  }
                />
                <TextCom size="xl" weight="lg" color="paragraph">
                  ChainPro ဆိုင်တွင် လာရောက်ယူဆောင်မည်
                </TextCom>
              </div> */}
              <div className="content-wrap">
                <TextCom size="xxl" weight="lg" className="mb-2">
                  ပစ္စည်းထုတ်ယူရန်လိပ်စာ
                </TextCom>
                <TextCom className="mb-2">
                  <TextCom as="span" weight="lg">
                    {translate("address", "address")}
                  </TextCom>{" "}
                  : {getShippingAddressByCode_data?.address1}
                  {/* {getShippingAddressByCode_data?.township?.name},{" "}
                  {getShippingAddressByCode_data?.state?.name},{" "}
                  {getShippingAddressByCode_data?.country?.name} */}
                </TextCom>
                {getShippingAddressByCode_data?.phone && (
                  <TextCom className="mb-2">
                    <TextCom as="span" weight="lg">
                      {translate("phone", "phone")}
                    </TextCom>{" "}
                    -{" "}
                    <TextCom
                      as="a"
                      href={`tel:${getShippingAddressByCode_data?.phone}`}
                      color="primary_light2"
                      textDecoration="underline"
                    >
                      {getShippingAddressByCode_data?.phone}
                    </TextCom>
                  </TextCom>
                )}
                {getShippingAddressByCode_data?.mobile && (
                  <TextCom className="mb-2 mobile">
                    <TextCom as="span" weight="lg">
                      {translate("mobile", "Mobile")}
                    </TextCom>{" "}
                    -{" "}
                    {mobile &&
                      mobile?.map((ph: any, key: any) => (
                        <TextCom
                          as="a"
                          href={`tel:${ph}`}
                          className="ph"
                          key={key}
                          color="primary_light2"
                          textDecoration="underline"
                        >
                          {ph}
                        </TextCom>
                      ))}
                  </TextCom>
                )}
                {getShippingAddressByCode_data?.email && (
                  <TextCom className="mb-2">
                    <TextCom as="span" weight="lg">
                      {translate("email", "email")}
                    </TextCom>{" "}
                    -{" "}
                    <TextCom
                      as="a"
                      href={`mailto:${getShippingAddressByCode_data?.email}`}
                      color="primary_light2"
                      textDecoration="underline"
                    >
                      {getShippingAddressByCode_data?.email}
                    </TextCom>
                  </TextCom>
                )}
                {getMetaData_data &&
                  getMetaData_data["map-link"] &&
                  getMetaData_data["map-link"]?.text_one !== false && (
                    <iframe
                      src={getMetaData_data["map-link"].text_one}
                      width="100%"
                      height="340"
                      allowFullScreen=""
                      loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"
                      className="mt-3 mb-3"
                    ></iframe>
                  )}
              </div>
            </div>
          </div>
          {/* ---- Delivery ----*/}
          <div className="col-lg-6">
            <div
              // className={`delivery-con ${delitype === "deli" ? "active" : ""}`
              className={`delivery-con`}
              // onClick={() =>
              //   getCart_data?.total >= pickup_price && setDeliType("deli")
              // }
            >
              {/* <div
                className="disable-layer"
                style={
                  delitype === "pickup"
                    ? { display: "block" }
                    : { display: "none" }
                }
              ></div> */}
              {/* <div className="d-flex gap-3 align-items-center title-wrap">
                <div
                  className={`myradio ${delitype === "deli" ? "active" : ""}`}
                >
                  <div className="inner"></div>
                </div>
                <TextCom size="xl" weight="lg" color="paragraph">
                  အိမ်တိုင်ရာရောက် ပို့ဆောင်ရန်
                </TextCom>
              </div> */}
              <div className="content-wrap">
                <TextCom size="xxl" weight="lg" className="content-title">
                  {/* ပစ္စည်းပို့ဆောင်ရန်လိပ်စာ */}
                  User Info
                </TextCom>
                {show && (
                  <TextCom
                    color="danger"
                    size="md"
                    className="select-info mb-2 d-flex align-items-center gap-2"
                  >
                    <AiOutlineInfoCircle color="#F41A1D" size={18} />
                    Please select shipping address
                  </TextCom>
                )}
                {!getProfile_data ? (
                  <ShippingForm
                    type="guest"
                    btn_name={translate("next", "Next")}
                    // route="/order/checkout"
                    guestForm={setguestForm}
                    from="guestadd"
                    delitype={delitype}
                  />
                ) : (
                  <>
                    {getShippingAddress_data?.data?.length > 0 ? (
                      <>
                        {getShippingAddress_data?.data?.map(
                          (add: any, key: any) => {
                            return (
                              <div
                                className={`address-con d-flex ${
                                  getShippingAddress_data?.data?.length ===
                                  key + 1
                                    ? "last"
                                    : ""
                                }`}
                                key={key}
                                onClick={() => {
                                  setSelectedAdd(add);
                                }}
                              >
                                <TextCom
                                  as="input"
                                  type="radio"
                                  value=""
                                  name="add"
                                  checked={
                                    selectedAdd?.id === add?.id ? true : false
                                  }
                                />
                                <div className="info">
                                  <TextCom className="name" weight="lg">
                                    {add?.name}
                                  </TextCom>
                                  <TextCom color="paragraph" className="ph">
                                    <FaMapMarkerAlt color="#000" size={14} />
                                    {add?.mobile}
                                  </TextCom>
                                  <TextCom color="paragraph">
                                    <FaPhoneAlt color="#000" size={13} />
                                    {add?.address1 +
                                      ", " +
                                      add?.township?.name +
                                      ", " +
                                      add?.state?.name +
                                      ", " +
                                      add?.country?.name}
                                  </TextCom>
                                </div>
                                <div className="btn d-flex justify-content-center align-items-center">
                                  <ButtonCom
                                    type="outline"
                                    bgcolor="transparent"
                                    onClick={() => handleModal(add?.id, "edit")}
                                  >
                                    <FiEdit size={18} color="#444444" />
                                  </ButtonCom>
                                  {getProfile_data && (
                                    <ButtonCom
                                      type="outline"
                                      bgcolor="transparent"
                                      onClick={() =>
                                        onHandleDelete("delete", add?.id)
                                      }
                                    >
                                      <RiDeleteBin7Line
                                        size={24}
                                        color="#444444"
                                      />
                                    </ButtonCom>
                                  )}
                                </div>
                              </div>
                            );
                          }
                        )}
                        <TextCom
                          as="a"
                          onClick={() => (setIsModal(!isModal), setFrom("add"))}
                        >
                          <div className="no-address w-100 d-flex gap-3 justify-content-center align-items-center gap-3">
                            <AiOutlinePlus size="23px" color="#000000" />
                            <TextCom weight="lg">
                              {translate("add-new-address", "Add New Address")}
                            </TextCom>
                          </div>
                        </TextCom>
                      </>
                    ) : (
                      <ShippingForm
                        type="guest"
                        btn_name={translate("next", "Next")}
                        // route="/order/checkout"
                        guestForm={setguestForm}
                        from="guestadd"
                        delitype={delitype}
                      />
                    )}
                    {/* <ButtonCom
                      className="next-btn"
                      btnBorderRadius="xxxs"
                      bgcolor="danger"
                      disabled={delitype === "" ? true : false}
                      onClick={() => handleNext(selectedAdd)}
                    >
                      {translate("next", "Next")}
                    </ButtonCom> */}
                  </>
                )}
              </div>
              {getMetaData_data && getMetaData_data["checkout-deli-info"] && (
                <TextCom style={{ paddingInline: "20px", marginTop: "20px" }}>
                  <TextCom
                    as="span"
                    color="danger"
                    style={{ marginRight: "5px" }}
                  >
                    *
                  </TextCom>
                  {getMetaData_data["checkout-deli-info"]?.text_one}
                </TextCom>
              )}
            </div>
          </div>
          {/* {getCart_data?.total >= pickup_price && (
          )} */}

          <ModalCom
            isModal={isModal}
            handleIsModal={setIsModal}
            isCloseBtn={true}
          >
            <div className="wrap">
              <ShippingForm
                btn_name={translate("add-new-address", "Add New Address")}
                modal={setIsModal}
                from={from}
                getGuestform={guestForm}
                guestForm={setguestForm}
                delitype={delitype}
              />
            </div>
          </ModalCom>
          {/* Delete Address Modal */}
          <ModalCom
            isModal={alertAddressDelete}
            handleIsModal={setalertAddressDelete}
            isCloseBtn={true}
          >
            <TextCom
              size="sm"
              weight="lg"
              className="d-flex flex-column justify-content-center align-items-center gap-3"
            >
              <BsExclamationCircle size={40} />
              {translate(
                "are-you-sure-you-want-to-delete-this-shipping-address",
                "Hello Are you sure you want to delete this shipping Address"
              )}
              ?
              <div className="mt-4 d-flex justify-content-center align-items-center w-100 gap-3">
                <ButtonCom
                  className="d-flex align-items-center justify-content-center"
                  btnBorderRadius="xxxs"
                  bgcolor="primary_light2"
                  color="light"
                  text={translate("cancel", "Cancel")}
                  onClick={() => setalertAddressDelete(false)}
                  style={{height:'68px'}}
                />
                <ButtonCom
                  className="d-flex align-items-center justify-content-center"
                  btnBorderRadius="xxxs"
                  bgcolor="primary_light2"
                  color="light"
                  text={translate("continue", "Continue")}
                  onClick={() => onHandleDelete(true)}
                  style={{height:'68px'}}
                />
              </div>
            </TextCom>
          </ModalCom>
        </div>

        {/* BuyMore and Next Buttons */}
        <ButtonCom
          className="buymore"
          btnBorderRadius="xxxs"
          bgcolor="primary_light2"
          onClick={() => navigate("/")}
        >
          {translate("buy-more", "Buy More")}
        </ButtonCom>
        {getProfile_data && getShippingAddress_data?.data?.length > 0 && (
          <ButtonCom
            className="next-btn"
            btnBorderRadius="xxxs"
            bgcolor="primary_light2"
            disabled={delitype === "" ? true : false}
            onClick={() => handleNext(selectedAdd)}
          >
            {translate("next", "Next")}
          </ButtonCom>
        )}
        {/* {(delitype === "pickup" || getCart_data?.total < pickup_price) && (
          <ButtonCom
            className="next-btn"
            btnBorderRadius="xxxs"
            bgcolor="danger"
            onClick={() =>
              handleNext(
                getCart_data?.total < pickup_price
                  ? getShippingAddressByCode_data
                  : selectedAdd
              )
            }
            style={getCart_data?.total < pickup_price ? { right: "26%" } : {}}
          >
            {translate("next", "Next")}
          </ButtonCom>
        )} */}
        {/* {!getProfile_data && getCart_data?.total >= pickup_price ? (
          <></>
        ) : (
        )} */}
      </div>
    </StyleCheckout1>
  );
};
