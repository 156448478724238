import React, { useEffect, useRef } from "react";
import Media from "react-media";

import { FiPlus, FiMinus } from "react-icons/fi";
import { useLocation } from "react-router-dom";
import { RiFilter2Fill } from "react-icons/ri";
import { TbAdjustmentsHorizontal } from "react-icons/tb";

import {
  TextCom,
  CollapseCom,
  CollapsePanel,
  ButtonCom,
  RangeSliderCom,
  CardCom,
  InputCom,
} from "components";
import { useHandleOther, useProduct, useHandleProduct } from "hook";
import { moneyFormat } from "utils";

type ProductSideBarFilterProps = {
  priceVal?: any;
  handleprice?: any;
  setprice?: any;
  setCurrentPagination?: any;
};

export const ProductSideBarFilter: React.FC<ProductSideBarFilterProps> = ({
  priceVal,
  handleprice,
  setprice,
  setCurrentPagination,
}) => {
  const location = useLocation();
  const {
    getProductCategory_data,
    getBrandList_data,
    getCountryOrigin_data,
    ProductAction,
    dispatch,
  } = useProduct();
  const { handleProductFilterChange, price, handlePriceFilter } =
    useHandleProduct();
  const { translate, langStore } = useHandleOther();

  const open_tab = getProductCategory_data?.key?.split("-");
  const onHandleChange = (filter_change_obj: any) => {
    handleProductFilterChange(filter_change_obj);
  };
  const langCode = langStore?.code;

  let rangemin = document.querySelector("input.range-min");
  let pricemin = document.querySelector("input#price-min");
  let rangemax = document.querySelector("input.range-max");
  let pricemax = document.querySelector("input#price-max");

  useEffect(() => {
    if (rangemin && pricemin && rangemax && pricemax) {
      rangemin.value = 0;
      pricemin.value = 0;
      rangemax.value = getProductCategory_data?.max_price;
      pricemax.value = getProductCategory_data?.max_price;
      priceVal({
        min_price: "",
        max_price: "",
      });
    }
  }, [getProductCategory_data?.max_price]);

  const handleCategoryChange = (item?: any) => {
    if (item?.item?.sub_category?.length === 0) {
      onHandleChange({
        type: "category",
        key: item?.item?.key,
        max_price: item?.item?.price_filter_max,
      });
    }
  };

  let brand_id: any = [];
  if (location.search.includes("brand")) {
    brand_id.push(location.search.split("=")[1].split("?")[0]);
  }
  let cate_id: any = [];
  if (location.search.includes("category")) {
    cate_id.push(location.search.split("=")[1].split("?")[0]);
  }
  const handleNestedRenderCategory = (category: any) => {
    return (
      category?.sub_category?.length > 0 && (
        <CollapseCom
          accordion
          expandIcon={({ isActive }: any) =>
            isActive ? <FiMinus /> : <FiPlus />
          }
          handleChange={handleCategoryChange}
          defaultActiveKey={[open_tab?.length > 2 ? open_tab?.[1] : ""]}
        >
          {category?.sub_category?.map((subcategory: any, index: any) => (
            <CollapsePanel
              header={() => (
                <TextCom
                  color={
                    (getProductCategory_data?.selected_ids ||
                      Number(cate_id[0])) == subcategory?.category_id
                      ? "primary"
                      : "text"
                  }
                >
                  {subcategory?.category_name}
                </TextCom>
              )}
              item={subcategory}
              key={index.toString()}
            >
              {handleNestedRenderCategory(subcategory)}
            </CollapsePanel>
          ))}
        </CollapseCom>
      )
    );
  };

  const filterHandlerMobile = () => {
    let collapseCon = document.querySelector(".filter-con");
    collapseCon?.classList.toggle("open");
  };

  useEffect(() => {
    dispatch(
      ProductAction.getCountryOrigin({
        category_id: getProductCategory_data?.selected_ids || "",
      })
    );
  }, [getProductCategory_data?.selected_ids]);

  let filter_ref = useRef<HTMLDivElement>(null);
  const handleClick = () => {
    filter_ref?.current?.classList.toggle("open");
  };

  const handleSelectChange = (e: any) => {
    if (e.target.value !== "all") {
      let min = e.target.value.split("to")[0];
      let max = e.target.value.split("to")[1];
      priceVal({
        min_price: min,
        max_price: max,
      });
    } else {
      priceVal({
        min_price: "",
        max_price: "",
      });
    }
  };

  return (
    <>
      {/* --------- collapse filter including category filter ---------- */}
      {/* <div className="d-flex filter-btns">
        <ButtonCom
          type="outline"
          bgColor="transparent"
          btnBorderRadius="xxxs"
          onClick={filterHandlerMobile}
        >
          <img src={Icon.FilterIcon} />
          <Media query={{ minWidth: 992 }}>
            {(matches) =>
              matches ? (
                <TextCom size="lg" weight="sm">
                  {translate("filter-by", "Filter By")}
                </TextCom>
              ) : (
                <TextCom size="lg" weight="sm">
                  {translate("filter", "Filter")}
                </TextCom>
              )
            }
          </Media>
        </ButtonCom>
      </div>
      <div className="filter-by d-flex">
        <div className="d-flex">
          <img src={Icon.FilterIcon} style={{ marginRight: "5px" }} />
          <TextCom size="lg" weight="sm" color="dark">
            {translate("filter-by", "Filter By")}
          </TextCom>
        </div>
      </div>
      <div className="collapse-con">
        <CollapseCom defaultActiveKey={["1", "2", "3", "4"]}>
          <CollapsePanel
            header={(isActive: any) => (
              <TextCom size="lg" weight="xl" color="dark">
                {translate("category", "Category")}
              </TextCom>
            )}
            className="category-collapse"
            key="1"
            style={{ paddingTop: "3px" }}
          >
            <CollapseCom
              expandIcon={({ isActive }: any) =>
                isActive ? <FiMinus /> : <FiPlus />
              }
              handleChange={handleCategoryChange}
              defaultActiveKey={[open_tab?.[0] || ""]}
            >
              {getProductCategory_data?.data?.map(
                (category: any, index: any) => (
                  <CollapsePanel
                    header={() => (
                      <TextCom
                        weight="lg"
                        color={
                          getProductCategory_data?.selected_ids ==
                          category?.category_id
                            ? "primary"
                            : "text"
                        }
                        onClick={() => {}}
                      >
                        {category?.category_name}
                      </TextCom>
                    )}
                    item={category}
                    key={index.toString()}
                    className=""
                  >
                    {handleNestedRenderCategory(category)}
                  </CollapsePanel>
                )
              )}
            </CollapseCom>
          </CollapsePanel>
          <hr />
          <CollapsePanel
            header={(isActive: any) => (
              <TextCom weight="xl">{translate("brand", "Brand")}</TextCom>
            )}
            className="multi-collapse brand"
            key="2"
          >
            {getBrandList_data &&
              getBrandList_data?.data?.map((brand: any, key: any) => (
                <ButtonCom
                  type="outline"
                  bgColor="transparent"
                  btnBorderRadius="0"
                  text={brand?.name}
                  key={key}
                  className={
                    brand?.check || Number(brand_id[0]) === brand?.ID
                      ? "selected"
                      : undefined
                  }
                  onClick={() =>
                    onHandleChange({ type: "brand", id: brand?.ID })
                  }
                />
              ))}
          </CollapsePanel>
          <hr />
          <CollapsePanel
            header={(isActive: any) => (
              <TextCom weight="xl">
                {translate("price", "Price")}({translate("ks", "ks")})
              </TextCom>
            )}
            className="price-collapse"
            key="3"
          >
            <RangeSliderCom
              change={(type?: string, value?: any) =>
                handleprice({ type, value })
              }
              setprice={setprice}
            />
          </CollapsePanel>
          <hr />
          <CollapsePanel
            header={(isActive: any) => (
              <TextCom weight="xl">
                {translate("country-of-origin", "Country of Origin")}
              </TextCom>
            )}
            className="multi-collapse country"
            key="4"
          >
            {getCountryOrigin_data?.data?.length > 0 &&
              getCountryOrigin_data?.data?.map((x: any, i: number) => (
                <ButtonCom
                  type="outline"
                  bgColor="transparent"
                  btnBorderRadius="0"
                  text={x?.name}
                  key={i}
                  className={x?.check ? "selected" : undefined}
                  onClick={() => onHandleChange({ type: "country", id: x?.ID })}
                />
              ))}
          </CollapsePanel>
          <hr />
        </CollapseCom>
      </div> */}
      <ButtonCom
        type="outline"
        bgcolor="transparent"
        btnBorderRadius="xxxs"
        text={translate("filter", "Filter")}
        onClick={() => handleClick()}
        borderColor="gray"
        SVGL={<TbAdjustmentsHorizontal />}
        className="filter-btn"
      />
      <div className="filter-wrap" ref={filter_ref}>
        {getBrandList_data?.data?.length > 0 && (
          <CardCom className="filter-card">
            <div className="title">
              <TextCom as="h1" size="lg" weight="xl">
                {translate("filter-by-brand", "Filter by brand")}
              </TextCom>
            </div>
            {getBrandList_data?.data?.map((x: any, i: any) => (
              <div key={i} className="check-item-wrap">
                <InputCom
                  isValidate={false}
                  checked={x?.check || false}
                  type="checkbox"
                  label={x?.name}
                  fun={onHandleChange}
                  parameter={{ type: "brand", id: x?.ID }}
                  // onClick={() => onHandleChange({ type: "brand", id: x?.ID })}
                />
              </div>
            ))}
          </CardCom>
        )}
        <CardCom className="filter-card">
          <div className="title">
            <TextCom as="h1" size="lg" weight="xl">
              {translate("filter-by-price", "Filter by price")}
            </TextCom>
          </div>
          <div className="filter-item-wrap d-flex gap-2">
            {/* <InputCom
              className="first-input"
              placeholder="min"
              value={price?.min_price}
              isValidate={false}
              type="number"
              onChange={(event: any) =>
                onHandleChange({ type: "min_price", event })
              }
            />
            <InputCom
              isValidate={false}
              placeholder="max"
              value={price?.max_price}
              type="number"
              onChange={(event: any) =>
                onHandleChange({ type: "max_price", event })
              }
            /> */}
            <InputCom
              isValidate={false}
              input_as="select"
              onChange={(e: any) => {
                  handleSelectChange(e)
                  setCurrentPagination(1)
                }
              }
              className="select-input"
            >
              <option value="all">{translate("all", "All")}</option>
              <option value={"0to10000"}>
                {langCode === "en" ? <>Under 10,000 {translate("ks", "Ks")}</> : <>၁၀,၀၀၀ {translate("ks", "Ks")} အောက်</>}
              </option>
              <option value={"10000to50000"}>
                {langCode === "en" ? (
                  <>10,000 {translate("ks", "Ks")} to 50,000 {translate("ks", "Ks")}</>
                ) : (
                  <>၁၀,၀၀၀ {translate("ks", "Ks")} မှ ၅၀,၀၀၀ {translate("ks", "Ks")}</>
                )}
              </option>
              <option value={"50000to100000"}>
                {langCode === "en" ? (
                  <>50,000 {translate("ks", "Ks")} to 100,000 {translate("ks", "Ks")}</>
                ) : (
                  <>၅၀,၀၀၀ {translate("ks", "Ks")} မှ ၁၀၀,၀၀၀ {translate("ks", "Ks")}</>
                )}
              </option>
              <option value={"100000to500000"}>
                {langCode === "en" ? (
                  <>100,000 {translate("ks", "Ks")} to 500,000 {translate("ks", "Ks")}</>
                ) : (
                  <>၁၀၀,၀၀၀ {translate("ks", "Ks")} မှ ၅၀၀,၀၀၀ {translate("ks", "Ks")}</>
                )}
              </option>
              <option value={"500000to "}>
                {langCode === "en" ? <>Upper 500,000 {translate("ks", "Ks")}</> : <>၅၀၀,၀၀၀ {translate("ks", "Ks")} အထက်</>}
              </option>
            </InputCom>
            <ButtonCom
              SVGL={<RiFilter2Fill size="20" color="#fff" />}
              onClick={handlePriceFilter}
              btnBorderRadius="xxxs"
              bgcolor="primary_light2"
            />
          </div>

          {/*---- to do price filter with dropdown -----*/}
        </CardCom>
        {getCountryOrigin_data?.data?.length > 0 && (
          <CardCom className="filter-card">
            <div className="title">
              <TextCom as="h1" size="lg" weight="xl">
                {translate("country-of-origin", "Country of origin")}
              </TextCom>
            </div>
            {getCountryOrigin_data?.data?.map((x: any, i: any) => (
              <div key={i} className="check-item-wrap">
                <InputCom
                  isValidate={false}
                  checked={x?.check || false}
                  type="checkbox"
                  label={x?.name}
                  onClick={() => onHandleChange({ type: "country", id: x?.ID })}
                />
              </div>
            ))}
          </CardCom>
        )}
      </div>
    </>
  );
};
